import { useMemo, useRef } from "react";
import { Image, NavBar } from "react-vant";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";
import { isNil, isEmpty } from "lodash-es";
import NiceModal from "@ebay/nice-modal-react";
import type * as room_type from "@api/http_resv/room/room_type";
import type * as room_srv from "@api/http_resv/room/room_srv";
import { RoomService } from "@api/http_resv/room/room_srv";
import { useInfiniteScroll } from "ahooks";
import type * as OrgConfig from "@api/http_resv/org/org_type";
import clsx from "clsx";
import { type Point } from "@api/http_resv/common/common_type";
import SearchIcon from "@/assets/icons/search.svg?react";
import CancelIcon from "@/assets/icons/cancel.svg?react";
import EmptySearch from "@/assets/images/empty_search.svg?react";
import LocationIcon from "@/assets/icons/location.svg?react";
import SubwayIcon from "@/assets/icons/subway.svg?react";
import Distance from "@/assets/icons/distance.svg?react";
import { genPageTitle } from "@/utils";
import { getRoomInfo, getDistanceInfo } from "@/utils/roomInfo";
import { toMMDD } from "@/lib/utils";
import { HeaderLodgingDates } from "./components";
// import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "@/constants/pagination";
import {
  SearchPopup,
  DateRangePopup,
  type DateRangePopupProps,
  Loading,
  ErrorComp,
  FixedHeaderWrapper,
  Card,
} from "@/components";
import NoPhoto from "@/assets/icons/no_photo.svg?react";
import { useCustomNavigate, useResvInfo } from "@/hooks";
import { PATHS } from "@/constants/path";
import { getMoneyText } from "@/utils/money";
import { genSmallRoomCoverUrl } from "@/utils/file";
import { useIsPC } from "@/stores";

export const RoomList = () => {
  const isPC = useIsPC();
  const customNavigate = useCustomNavigate();

  const ref = useRef<HTMLDivElement>(null);

  const { address, setAddress, occupancy, setOccupancy, adultCount } =
    useResvInfo();

  const ignoreThisYear = useMemo(() => {
    if (isNil(occupancy)) {
      return false;
    }
    const isThisYear = dayjs(occupancy?.endDate).isSame(dayjs(), "years");
    if (isThisYear) {
      const isSameYear = dayjs(occupancy.startDate).isSame(
        dayjs(occupancy.endDate),
        "years",
      );
      return isSameYear && isThisYear;
    }

    return false;
  }, [occupancy]);

  const {
    data: listData,
    loading,
    noMore,
    error,
  } = useInfiniteScroll(
    async () => {
      // const page = data ? Math.ceil(d.list.length / DEFAULT_PAGE_SIZE) + 1 : 1;
      // const start = (page - 1) * DEFAULT_PAGE_SIZE;
      // const end = page * DEFAULT_PAGE_SIZE;
      const params: room_srv.ListRoomReq = {
        // current: page,
        // pageSize: DEFAULT_PAGE_SIZE,
        current: 1,
        pageSize: 100,
        priceQuery: {
          startDate: occupancy?.startDate,
          endDate: occupancy?.endDate,
          adultCount,
        },
      };

      if (address?.point.lat && address?.point.lng) {
        params.point = address?.point;
      }

      const resp = await RoomService.ListRoom(params);
      const result = {
        list: resp.rooms,
        total: resp.total,
      };
      return result;
    },
    {
      target: ref,
      reloadDeps: [address, occupancy, adultCount],
      isNoMore: (
        data: { list: room_type.RoomInList[]; total: number } | undefined,
      ) => {
        return data?.list?.length! >= data?.total!;
      },
    },
  );

  const showDateRangePopup = async () => {
    const params: DateRangePopupProps = {
      value: occupancy,
    };
    const res: {
      startDate: string;
      endDate: string;
    } = await NiceModal.show(DateRangePopup, params);

    setOccupancy(res);
  };

  const showSearchPopup = async () => {
    const res: OrgConfig.PopularLocation = await NiceModal.show(
      SearchPopup,
      {},
    );
    setAddress(res);
  };

  const deleteAddress = (e: React.MouseEvent) => {
    e.stopPropagation();

    setAddress(undefined);
  };

  if (error) {
    return <ErrorComp error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Helmet>
        <title>{genPageTitle("房间列表")}</title>
      </Helmet>
      {isPC ? (
        <FixedHeaderWrapper
          pageName="房间列表"
          type="compact"
          goBack={() => {
            customNavigate(PATHS.HOME);
          }}
        >
          <div className="flex w-full items-center gap-6">
            <div className="w-1/2 cursor-pointer">
              <HeaderLodgingDates value={occupancy} onChange={setOccupancy} />
            </div>
            <div className="w-1/2 cursor-pointer">
              <SearchArea
                address={address}
                onSearchArea={showSearchPopup}
                deleteSearchArea={deleteAddress}
              />
            </div>
          </div>
        </FixedHeaderWrapper>
      ) : (
        <NavBar
          title="房间列表"
          leftText="返回"
          placeholder={true}
          fixed={true}
          zIndex={50}
          onClickLeft={() => {
            customNavigate(PATHS.HOME);
          }}
        />
      )}

      {/* 搜索条件 */}
      {!isPC && (
        <div className="bg-page-bg sticky inset-x-0 top-10 z-10 flex space-x-2 px-4 py-3">
          <div
            className="flex flex-col justify-center rounded-xl bg-white px-3"
            onClick={showDateRangePopup}
          >
            <div className="flex items-center text-xs font-medium">
              <span className="text-text-666 mr-2">住</span>
              <span className="text-easbnb-brand">
                {occupancy?.startDate
                  ? toMMDD(occupancy?.startDate, { ignoreThisYear })
                  : "请选择"}
              </span>
            </div>
            <div className="flex items-center text-xs font-medium">
              <span className="text-text-666 mr-2">离</span>
              <span className="text-easbnb-brand">
                {occupancy?.endDate
                  ? toMMDD(occupancy?.endDate, { ignoreThisYear })
                  : "请选择"}
              </span>
            </div>
          </div>
          <SearchArea
            address={address}
            onSearchArea={showSearchPopup}
            deleteSearchArea={deleteAddress}
          />
        </div>
      )}
      {/* 列表 */}
      <div
        ref={ref}
        className={clsx(
          isPC ? "mx-auto mt-5" : "size-full  space-y-6",
          "bg-page-bg flex flex-1 cursor-pointer flex-col overflow-y-auto px-4 ",
        )}
      >
        <div>
          {listData?.list.map((roomInList) => (
            <RoomListItem roomInList={roomInList} key={roomInList.room.id} />
          ))}
        </div>

        {listData?.list.length === 0 && (
          <div className="flex flex-col items-center pt-24">
            <EmptySearch />
            <div className="text-text-4 mt-3 text-sm">暂无搜索结果</div>
          </div>
        )}
        {noMore && (
          <div
            className={clsx(
              isPC ? "px-3 py-6" : "py-6",
              "text-text-3 flex justify-center  text-sm",
            )}
          >
            已经到底啦～
          </div>
        )}
      </div>
    </div>
  );
};

const RoomListItem: React.FC<{ roomInList: room_type.RoomInList }> = ({
  roomInList,
}) => {
  const customNavigate = useCustomNavigate();
  const isPC = useIsPC();

  const roomInfoText = getRoomInfo({
    area: roomInList.room.roomSize,
    bed: roomInList.room.singleBedCount,
    doubleBed: roomInList.room.doubleBedCount,
  });
  const distanceText = getDistanceInfo(roomInList?.distance ?? "");

  const goToRoomDetail = (id: string) => {
    customNavigate(PATHS.ROOM, {
      id,
      state: {
        backPath: PATHS.ROOM_SEARCH,
      },
    });
  };

  return (
    <div
      className={clsx(
        {
          "mx-auto w-[80vw] min-w-[720px] max-w-screen-lg rounded-xl hover:shadow-[0px_4px_24px_0px_#272C3E0F]":
            isPC,
        },
        "mb-3 flex flex-col ",
      )}
      key={roomInList.room.id}
      onClick={() => goToRoomDetail(roomInList.room.id)}
    >
      {isPC ? (
        <Card className="flex gap-6 p-2 ">
          <div className="h-[260px] w-[432px]">
            <RoomListItemImage roomInList={roomInList} />
          </div>
          <div className="flex-1">
            <RoomListItemInfo
              roomInList={roomInList}
              distanceText={distanceText}
              roomInfoText={roomInfoText}
            />
          </div>
        </Card>
      ) : (
        <>
          <RoomListItemImage roomInList={roomInList} />
          <RoomListItemInfo
            roomInList={roomInList}
            distanceText={distanceText}
            roomInfoText={roomInfoText}
          />
        </>
      )}
    </div>
  );
};

const RoomListItemInfo: React.FC<{
  roomInList: room_type.RoomInList;
  distanceText?: string;
  roomInfoText?: string;
}> = ({ roomInList, distanceText, roomInfoText }) => {
  const isPC = useIsPC();

  return (
    <div className={clsx({ "py-4": isPC }, "flex flex-col")}>
      <div
        className={clsx(
          {
            "border-b border-[#E9F0FF] pb-4": isPC,
          },
          "flex flex-col",
        )}
      >
        <div
          className={clsx(
            isPC ? "line-clamp-2 whitespace-pre-line" : "mb-2",
            "text-text-1 text-xl font-medium",
          )}
        >
          {roomInList.room.title}
        </div>

        {(distanceText ||
          !isEmpty(distanceText) ||
          roomInList.room.roomSize > 0 ||
          roomInList.room.personCapacity > 0) && (
          <div
            className={clsx(
              isPC ? "mt-2.5" : "mb-2",
              "flex items-center space-x-2",
            )}
          >
            {distanceText && !isEmpty(distanceText) && (
              <span className="flex items-center rounded-md border border-[#EC642B] bg-[#FDF6EF] px-1.5 py-1 text-sm ">
                <Distance className="size-2" />
                <span className="ml-1 text-[#EC642B]">{distanceText}</span>
              </span>
            )}
            {roomInList.room.roomSize > 0 && (
              <span className="text-text-666 text-sm">{roomInfoText}</span>
            )}
            {roomInList.room.personCapacity > 0 && (
              <span className="text-easbnb-brand text-sm">
                可住{roomInList.room.personCapacity}人
              </span>
            )}
          </div>
        )}
      </div>
      {roomInList.room.address && (
        <div
          className={clsx(isPC ? "mb-2.5 pt-4" : "mb-2", "flex items-start")}
        >
          <div>
            <LocationIcon className="mr-1 mt-0.5 size-4" />
          </div>
          <div className="text-text-666 text-sm">{roomInList.room.address}</div>
        </div>
      )}
      {roomInList.room.traffic && (
        <div className="mb-2 flex items-start">
          <div>
            <SubwayIcon className="mr-1 mt-0.5 size-4" />
          </div>
          <div className="text-text-666 line-clamp-3 flex-1 whitespace-pre-line text-sm">
            {roomInList.room.traffic}
          </div>
        </div>
      )}

      {!roomInList.room.address && !roomInList.room.traffic && (
        <div
          className={clsx(isPC ? "mb-2.5 pt-4" : "mb-2", "flex items-start")}
        >
          <div>
            <LocationIcon className="mr-1 mt-0.5 size-4" />
          </div>
          <div className="text-text-666 text-sm">暂无位置、交通方式等信息</div>
        </div>
      )}
    </div>
  );
};

const RoomListItemImage: React.FC<{ roomInList: room_type.RoomInList }> = ({
  roomInList,
}) => {
  const isPC = useIsPC();

  return (
    <div
      className={clsx(
        isPC ? "h-[260px]" : "h-[200px]",
        "relative mb-2 overflow-hidden rounded-xl bg-[#F2F0F4]",
      )}
    >
      {roomInList?.imageUri ? (
        <Image src={genSmallRoomCoverUrl(roomInList.imageUri)} fit="cover" />
      ) : (
        <div
          className={clsx(
            isPC ? "h-[260px]" : "h-[200px]",
            "flex w-full items-center justify-center bg-[#F2F0F4]",
          )}
        >
          <NoPhoto />
          <span className="text-text-3 absolute bottom-1/4 translate-y-1/2 text-sm">
            暂无图片
          </span>
        </div>
      )}
      {roomInList?.quotedPrice && roomInList?.basePrice && (
        <div
          className={clsx(
            isPC ? "bottom-3 right-3 px-3" : "bottom-2 right-2 px-2",
            "absolute flex items-center space-x-2 rounded-xl bg-white/85 px-2 py-1",
          )}
        >
          <span className="text-lg font-medium text-[#F74D36]">
            {getMoneyText(
              roomInList?.quotedPrice?.amount,
              roomInList?.quotedPrice?.currency,
              { hidePlusSign: true },
            )}
          </span>
          {roomInList?.quotedPrice?.amount < roomInList?.basePrice?.amount && (
            <span className="text-text-2 text-xs font-medium line-through">
              {getMoneyText(
                roomInList?.basePrice?.amount,
                roomInList?.basePrice?.currency,
                { hideLabel: true, hidePlusSign: true },
              )}
            </span>
          )}
        </div>
      )}
      {roomInList?.lowestPrice && (
        <div
          className={clsx(
            isPC ? "bottom-3 right-3 px-3" : "bottom-2 right-2 px-2",
            "absolute flex items-center space-x-2 rounded-xl bg-white/85 py-1",
          )}
        >
          <span className="text-lg font-medium text-[#F74D36]">
            {getMoneyText(
              roomInList?.lowestPrice?.amount,
              roomInList?.lowestPrice?.currency,
              { hidePlusSign: true },
            )}
          </span>
          <span className="text-text-2 text-xs font-medium"> 起/晚 </span>
        </div>
      )}
    </div>
  );
};

const SearchArea: React.FC<{
  address: { name: string; point: Point } | undefined;
  onSearchArea: () => void;
  deleteSearchArea: (e: any) => void;
}> = ({ address, onSearchArea, deleteSearchArea }) => {
  const isPC = useIsPC();

  return (
    <div
      className={clsx(
        isPC ? "bg-[#F5F7FB]" : "bg-white",
        "flex flex-1 items-center space-x-2 rounded-xl  p-3",
      )}
      onClick={onSearchArea}
    >
      <SearchIcon className="size-4" />
      {address?.name ? (
        <div className="text-text-1 line-clamp-1 w-full flex-1 text-ellipsis text-sm">
          {address?.name}
        </div>
      ) : (
        <div className="text-text-4 w-full flex-1 text-sm">
          搜索附近的地区或车站
        </div>
      )}

      {address?.name && (
        <CancelIcon className="size-4" onClick={(e) => deleteSearchArea(e)} />
      )}
    </div>
  );
};
