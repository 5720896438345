/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";
import { type PriceQuery, type Tenant } from "../common/common_type";
import { type CurrencyAmount } from "../../http_pms/common/common_type";
import { type Order, type OrderInList, type OrderInDetail } from "./order_type";

export namespace OrderService {
  // 预定
  export function Book(req: BookReq, config?: any) {
    return webapi.post<BookResp>("/api/v1/OrderService_Book", req, config);
  }

  // 签署协议
  export function SignContract(req: SignContractReq, config?: any) {
    return webapi.post<SignContractResp>(
      "/api/v1/OrderService_SignContract",
      req,
      config,
    );
  }

  // 支付
  export function Pay(req: PayReq, config?: any) {
    return webapi.post<PayResp>("/api/v1/OrderService_Pay", req, config);
  }

  // 支付校验
  export function CheckPaid(req: CheckPaidReq, config?: any) {
    return webapi.post<CheckPaidResp>(
      "/api/v1/OrderService_CheckPaid",
      req,
      config,
    );
  }

  // 订单列表
  export function List(req: ListReq, config?: any) {
    return webapi.post<ListResp>("/api/v1/OrderService_List", req, config);
  }

  // 订单详情
  export function Get(req: GetReq, config?: any) {
    return webapi.post<GetResp>("/api/v1/OrderService_Get", req, config);
  }

  // 获取微信端内支付Config
  export function GetPayConfig(req: GetPayConfigReq, config?: any) {
    return webapi.post<GetPayConfigResp>(
      "/api/v1/OrderService_GetPayConfig",
      req,
      config,
    );
  }
}
// BookReq
export interface BookReq {
  roomId: string;
  priceQuery: PriceQuery;
  // 金额 使用计算出来的实际金额下单
  checkPrice: CurrencyAmount;
  // 联系人信息
  tenant: Tenant;
  // 备注
  remark: string;
}

export interface BookResp {
  order: Order;
  // false 新创建的账号 @bool
  isNewAccount: boolean;
}

export interface SignContractReq {
  // 不鉴权
  orderId: string;
}

export interface SignContractResp {
  order: Order;
}

// Pay
export interface PayReq {
  orderId: string;
  // 支付方式
  payChannel: string;
  // 是否在微信端内 @bool
  inWechat: boolean;
  // 重定向路径
  redirectUri: string;
}

export interface PayResp {
  endpoint: string;
}

// GetPayConfig
export interface GetPayConfigReq {
  orderId: string;
  // 支付方式
  payChannel: string;
  // 重定向路径
  redirectUri: string;
  // 用户code
  code: string;
}

// This the module of GetPayConfigResp
export namespace GetPayConfigResp {
  export interface Config {
    // 公众号的唯一标识
    appId: string;
    // 生成签名的时间戳 @uint64
    timeStamp: string;
    // 生成签名的随机串
    nonceStr: string;
    // 签名
    signature: string;
  }

  export interface WxPayParams {
    // 公众号的唯一标识
    appId: string;
    // 生成签名的时间戳 @uint64
    timeStamp: string;
    // 生成签名的随机串
    nonceStr: string;
    // JSAPI下单接口返回的prepay_id参数值，提交格式如：prepay_id=***
    package: string;
    // 签名类型
    signType: string;
    // 签名
    paySign: string;
  }
}
export interface GetPayConfigResp {
  config: GetPayConfigResp.Config;
  params: GetPayConfigResp.WxPayParams;
}

export interface ListReq {
  // 当前页码 @uint32
  current: number;
  // 每页数量 @uint32
  pageSize: number;
}

export interface ListResp {
  orders: OrderInList[];
  // 当前页码 @uint32
  current: number;
  // 每页数量 @uint32
  pageSize: number;
  // 总数 @uint32
  total: number;
}

export interface GetReq {
  // 需要校验登录态
  id: string;
}

export interface GetResp {
  order: OrderInDetail;
}

export interface CheckPaidReq {
  id: string;
}

export interface CheckPaidResp {
  // awaiting-等待, successful-成功, error-错误
  status: string;
  email: string;
  //  @uint64
  cancelledAt: string;
  //  @bool
  isCancelled: boolean;
  price: CurrencyAmount;
}
