import { CACHE_KEY_BRAND_NAME } from "@/constants/cache";
import { BRAND_NAME } from "@/constants/text";

export const getBrandName = () => {
  return localStorage.getItem(CACHE_KEY_BRAND_NAME) || BRAND_NAME;
};

export const setBrandName = (name: string) => {
  localStorage.setItem(CACHE_KEY_BRAND_NAME, name);
};
