import { useLocalStorageState } from "ahooks";
import {
  MAX_SEARCH_HISTORY_CACHE,
  CACHE_KEY_SEARCH_HISTORY,
} from "@/constants/cache";

export const useSearchHistory = () => {
  const [_historyList, setHistoryList] = useLocalStorageState<string[]>(
    CACHE_KEY_SEARCH_HISTORY,
    {
      defaultValue: [],
    },
  );

  const historyList = _historyList || [];

  const pushHistory = (keyword: string) => {
    if (historyList.includes(keyword) || !keyword) {
      return;
    }

    if (historyList.length < MAX_SEARCH_HISTORY_CACHE) {
      setHistoryList([keyword, ...historyList]);
    } else {
      setHistoryList([
        keyword,
        ...historyList.slice(0, MAX_SEARCH_HISTORY_CACHE - 1),
      ]);
    }
  };

  const clearHistory = () => {
    setHistoryList([]);
  };

  return { historyList, pushHistory, clearHistory };
};
