import { Helmet } from "react-helmet";
import { useCountDown, useRequest } from "ahooks";
import { OrderService } from "@api/http_resv/order/order_srv";
import { isNil, isUndefined, isEmpty } from "lodash-es";
import { NavBar } from "react-vant";
import { useState } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import {
  Loading,
  ErrorComp,
  FixedBottomWrapper,
  FixedHeaderWrapper,
} from "@/components";
import { useCustomNavigate } from "@/hooks";
import {
  RoomCard,
  CheckInOutCard,
  OrderStatusCard,
  AgreementCard,
} from "../components";
import { genPageTitle } from "@/utils";
import { makeStorageUrl } from "@/utils/file";
import { getOrderStatus, OrderStatusEnum } from "@/utils/order";
import { PATHS } from "@/constants/path";
import { useIsPC } from "@/stores";
import {
  CheckInMethodCard,
  PersonInfoCard,
  CheckOutPolicyCard,
  OrderInfoCard,
  PersonNoticeCard,
  DetailRoomCard,
} from "./components";
import { getMoneyText } from "@/utils/money";

export const OrderDetail = () => {
  const customNavigate = useCustomNavigate();
  const params = useParams<{ id?: string }>();
  const id = params.id;
  const [orderStatus, setOrderStatus] = useState<OrderStatusEnum>();
  const isPC = useIsPC();

  const { data, loading, refresh, error } = useRequest(
    async () => {
      const resp = await OrderService.Get({
        id: id!,
      });

      const countDownDate = resp.order.order.isCancelled
        ? undefined
        : Math.max(
            Number(resp?.order.order.cancelledAt) * 1000,
            Date.now() + 5000,
          );

      const status = getOrderStatus({
        dateStart: resp.order.order.startDate,
        dateEnd: resp.order.order.endDate,
        paidAt: resp.order.order.paidAt,
        isCancelled: resp.order.order.isCancelled,
      });

      setOrderStatus(status);
      return { ...resp.order, countDownDate };
    },
    {
      ready: !isUndefined(id),
    },
  );

  const [, formattedRes] = useCountDown({
    targetDate: data?.countDownDate,
    onEnd: () => {
      refresh();
    },
  });

  const onGoToPay = () => {
    if (isNil(data)) {
      return;
    }

    const path =
      data?.order.needSignContract && isEmpty(data?.order.signedAt)
        ? PATHS.ORDER_AGREEMENT
        : PATHS.PAY;

    customNavigate(path, {
      id: data.order.id,
      state: {
        isSigned: false,
      },
    });
  };

  if (!id) {
    return (
      <div className="flex h-[50vh] w-full items-center justify-center">
        <Helmet>
          <title>{genPageTitle("订单详情")}</title>
        </Helmet>
        <div className="text-text-4 text-sm">无法获取订单信息</div>
      </div>
    );
  }

  if (error) {
    return <ErrorComp refresh={refresh} error={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Helmet>
        <title>{genPageTitle("订单详情")}</title>
      </Helmet>

      {isPC ? (
        <FixedHeaderWrapper
          pageName="订单详情"
          goBack={() => {
            customNavigate(PATHS.ORDER);
          }}
        />
      ) : (
        <NavBar
          title="订单详情"
          leftText="返回"
          placeholder={true}
          zIndex={50}
          onClickLeft={() => {
            customNavigate(PATHS.ORDER);
          }}
        />
      )}
      <div className="bg-page-bg overflow-y-auto px-4 pb-8">
        {data && (
          <div
            className={clsx(
              {
                "w-[80vw] max-w-screen-lg": isPC,
              },
              "mx-auto flex flex-col space-y-3 pt-3",
            )}
          >
            <OrderStatusCard
              orderInDetail={data}
              orderStatus={orderStatus}
              formattedRes={formattedRes}
            />
            {isPC ? (
              <>
                <DetailRoomCard
                  room={data.room}
                  imageUri={makeStorageUrl(data.roomImageUri)}
                  quotedPrice={data.order.price}
                  orderId={id}
                  disableLink={true}
                />
                <div className="flex space-x-4">
                  <div className="flex flex-1 flex-col space-y-4">
                    {Boolean(data?.order.needSignContract) && (
                      <AgreementCard
                        orderId={data?.order.id}
                        signedAt={data?.order.signedAt}
                        contractName={data?.order.contractName}
                      />
                    )}
                    <CheckInMethodCard data={data} />
                    <PersonNoticeCard data={data} />
                    <CheckOutPolicyCard data={data} />
                    <PersonInfoCard data={data} />
                  </div>
                  <div className="w-[360px] space-y-4">
                    <CheckInOutCard
                      startDate={data.order.startDate}
                      endDate={data.order.endDate}
                      adultCount={data.order.adultCount}
                    >
                      {isPC && orderStatus === OrderStatusEnum.Unpaid && (
                        <>
                          <div className="bg-page-bg mt-6 flex h-16 items-center justify-between rounded-xl px-4">
                            <span className="text-text-666 text-sm">
                              订单金额
                            </span>
                            <span className="text-danger text-2xl font-medium">
                              {getMoneyText(
                                data.order.price.amount,
                                data.order.price.currency,
                                {
                                  hidePlusSign: true,
                                  hideLabel: true,
                                },
                              )}
                            </span>
                          </div>
                          <div
                            className="hover:bg-text-333 mt-8 cursor-pointer rounded-full bg-[#3D3D3D] px-20 py-3 text-center text-base font-medium text-white"
                            onClick={onGoToPay}
                          >
                            {data?.order.needSignContract &&
                            (isEmpty(data?.order.signedAt) ||
                              isUndefined(data?.order.signedAt))
                              ? "签署协议并支付"
                              : "去支付"}
                          </div>
                        </>
                      )}
                    </CheckInOutCard>
                    <OrderInfoCard data={data} />
                  </div>
                </div>
              </>
            ) : (
              <>
                <RoomCard
                  room={data.room}
                  imageUri={makeStorageUrl(data.roomImageUri)}
                  quotedPrice={data.order.price}
                  orderId={id}
                  disableLink={true}
                />
                <CheckInOutCard
                  startDate={data.order.startDate}
                  endDate={data.order.endDate}
                  adultCount={data.order.adultCount}
                />
                {Boolean(data?.order.needSignContract) && (
                  <AgreementCard
                    orderId={data?.order.id}
                    signedAt={data?.order.signedAt}
                    contractName={data?.order.contractName}
                  />
                )}

                <CheckInMethodCard data={data} />
                <PersonNoticeCard data={data} />
                <CheckOutPolicyCard data={data} />
                <PersonInfoCard data={data} />
                <OrderInfoCard data={data} />
              </>
            )}
          </div>
        )}

        {orderStatus === OrderStatusEnum.Unpaid && !isPC && (
          <FixedBottomWrapper className="flex w-full items-center justify-center bg-white py-3">
            <button
              type="button"
              className="hover:bg-text-333 rounded-full bg-[#3D3D3D] px-20 py-3 text-base font-medium text-white"
              onClick={onGoToPay}
            >
              {data?.order.needSignContract &&
              (isEmpty(data?.order.signedAt) ||
                isUndefined(data?.order.signedAt))
                ? "签署协议并支付"
                : "去支付"}
            </button>
          </FixedBottomWrapper>
        )}
      </div>
    </div>
  );
};
