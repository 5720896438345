import { getBrandName } from "./cache";

export const genPageTitle = (title?: string) => {
  const brandName = getBrandName();
  if (!title) return brandName;
  return `${title} - ${brandName}`;
};

export const genHighlightTextHTML = (text: string, keyword?: string) => {
  if (!keyword) return text;
  const reg = new RegExp(keyword, "g");
  return text.replace(reg, `<span class="text-easbnb-brand">${keyword}</span>`);
};

export const sleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const genNumbers = (min: number, max: number): number[] => {
  const numbers: number[] = [];
  let current = min;

  while (current <= max) {
    numbers.push(current);
    current++;
  }

  return numbers;
};
