import { Helmet } from "react-helmet";
import { OrderService } from "@api/http_resv/order/order_srv";
import { useRequest } from "ahooks";
import { isUndefined } from "lodash-es";
import { useLocation, useParams, type Location } from "react-router-dom";
import clsx from "clsx";
import { genPageTitle } from "@/utils";
import SuccessIcon from "@/assets/icons/success.svg?react";
import PendingIcon from "@/assets/icons/pending.svg?react";
import WechatMiniIcon from "@/assets/icons/wechat_mini.svg?react";
import ErrorIcon from "@/assets/icons/error.svg?react";
import { getMoneyText } from "@/utils/money";
import { PATHS } from "@/constants/path";
import { useCustomNavigate } from "@/hooks";
import { Card, ErrorComp, FixedHeaderWrapper } from "@/components";
import { useIsPC } from "@/stores";
import { getRedirectUrl } from "@/utils/url";

export const PayResult = () => {
  const isPC = useIsPC();
  const customNavigate = useCustomNavigate();
  const params = useParams<{ id?: string }>();
  const id = params.id;
  const {
    data: orderData,
    loading: getOrderLoading,
    error: orderError,
  } = useRequest(
    async () => {
      const resp = await OrderService.Get({
        id: id!,
      });

      return resp.order;
    },
    {
      ready: !isUndefined(id),
    },
  );

  const {
    data: checkPaidResp,
    cancel: cancelPolling,
    error: checkPaidError,
  } = useRequest(
    async () => {
      const resp = await OrderService.CheckPaid({
        id: id!,
      });

      return resp;
    },
    {
      ready: !isUndefined(id),
      pollingInterval: 3000,
      onSuccess: (resp) => {
        if (resp.status === "awaiting") {
          return;
        }
        cancelPolling();
      },
    },
  );

  // awaiting-等待, successful-成功, error-错误
  const paidSuccess = checkPaidResp?.status === "successful";
  const paidError = checkPaidResp?.status === "error";
  const paidAwaiting = checkPaidResp?.status === "awaiting";

  // 不是上面3种状态的其它状态
  const paidOtherStatus = !paidSuccess && !paidError && !paidAwaiting;

  const location = useLocation() as Location<{ endpoint?: string }>;

  if (!id) {
    return (
      <div className="flex h-full flex-col">
        <Helmet>
          <title>{genPageTitle("支付结果")}</title>
        </Helmet>
        {isPC && (
          <FixedHeaderWrapper
            onlyTitle={true}
            goBack={() =>
              customNavigate(PATHS.ORDER, {
                id,
              })
            }
          />
        )}
        <Card
          className={clsx(
            isPC
              ? "mx-auto my-3 h-full w-[80vw] max-w-screen-lg flex-1 pt-20"
              : "h-screen",
            "flex flex-col items-center overflow-y-auto",
          )}
        >
          <div className="text-text-4 text-sm">无法获取支付结果信息</div>
        </Card>
      </div>
    );
  }

  if (orderError || checkPaidError) {
    return <ErrorComp error={orderError || checkPaidError} />;
  }

  if (getOrderLoading || paidAwaiting || paidOtherStatus) {
    return (
      <div className="flex h-full flex-col">
        <Helmet>
          <title>{genPageTitle("支付中")}</title>
        </Helmet>
        {isPC && (
          <FixedHeaderWrapper
            onlyTitle={true}
            goBack={() =>
              customNavigate(PATHS.ORDER, {
                id,
              })
            }
          />
        )}

        <Card
          className={clsx(
            isPC
              ? "mx-auto my-3 h-full w-[80vw] max-w-screen-lg flex-1 pt-20"
              : "h-screen",
            "flex flex-col items-center overflow-y-auto",
          )}
        >
          <div className="mb-6 mt-[70px]">
            <PendingIcon className="size-[52px] animate-spin" />
          </div>
          <span className="text-text-1 mb-3 text-2xl font-medium">支付中</span>
          {orderData && (
            <div className="flex items-center">
              <span className="text-text-3 text-sm">支付金额：</span>
              <span className="text-xl font-medium text-[#FF1010]">
                {getMoneyText(
                  orderData.order.price.amount,
                  orderData.order.price.currency,
                  {
                    hidePlusSign: true,
                  },
                )}
              </span>
            </div>
          )}
          {location?.state?.endpoint && (
            <>
              <img
                className="my-6 size-[240px]"
                src={`https://easit.cc/?qr=${encodeURIComponent(getRedirectUrl(location.state.endpoint))}`}
              />
              <div className="flex items-center gap-2">
                <WechatMiniIcon className="size-4" />
                <span className="text-text-999 text-sm">
                  请使用微信扫码支付
                </span>
              </div>
            </>
          )}
          {renderFooterButton()}
        </Card>
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col">
      <Helmet>
        <title>{genPageTitle("支付结果")}</title>
      </Helmet>
      {isPC && (
        <FixedHeaderWrapper
          onlyTitle={true}
          goBack={() =>
            customNavigate(PATHS.ORDER, {
              id,
            })
          }
        />
      )}

      <Card
        className={clsx(
          isPC
            ? "mx-auto my-3 h-full w-[80vw] max-w-screen-lg flex-1 pt-20"
            : "h-screen",
          "flex flex-col items-center overflow-y-auto",
        )}
      >
        {orderData && paidSuccess && (
          <>
            <div className="mb-6 mt-[70px]">
              <SuccessIcon className="size-[52px]" />
            </div>
            <span className="text-text-1 mb-3 text-2xl font-medium">
              支付成功
            </span>
            <div className="flex items-center">
              <span className="text-text-3 text-sm">实付金额：</span>
              <span className="text-xl font-medium text-[#FF1010]">
                {getMoneyText(
                  orderData.order.price.amount,
                  orderData.order.price.currency,
                  {
                    hidePlusSign: true,
                  },
                )}
              </span>
            </div>
            <span className="text-text-3 mt-3 w-screen px-12 text-center text-sm">
              当前账号没有绑定登录方式，已将账号密码发送至
              {orderData.tenant.email}
            </span>
          </>
        )}
        {orderData && paidError && (
          <>
            <div className="mb-6 mt-[70px]">
              <ErrorIcon className="size-[52px]" />
            </div>
            <span className="text-text-1 mb-3 text-2xl font-medium">
              支付失败
            </span>
            <div className="flex items-center">
              <span className="text-text-3 text-sm">实付金额：</span>
              <span className="text-xl font-medium text-[#FF1010]">
                {getMoneyText(
                  orderData.order.price.amount,
                  orderData.order.price.currency,
                  {
                    hidePlusSign: true,
                  },
                )}
              </span>
            </div>
          </>
        )}

        {renderFooterButton()}
      </Card>
    </div>
  );

  function renderFooterButton() {
    if (paidError) {
      return (
        <button
          type="button"
          className="bg-resv-black mt-[70px] w-60 rounded-full px-16 py-3 text-base font-medium text-white"
          onClick={() => {
            customNavigate(PATHS.PAY, {
              id,
            });
          }}
        >
          返回支付
        </button>
      );
    }

    return (
      <button
        type="button"
        className="bg-resv-black mt-[70px] w-60 rounded-full px-16 py-3 text-base font-medium text-white"
        onClick={() => {
          customNavigate(PATHS.ORDER, {
            id,
          });
        }}
      >
        查看订单
      </button>
    );
  }
};
