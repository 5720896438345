import { create } from "zustand";
import { persist } from "zustand/middleware";
import {
  CACHE_KEY_ROUTER_HISTORY,
  MAX_HISTORY_STACK_CACHE,
} from "@/constants/cache";

interface State {
  historyStack: string[];
}

interface Action {
  pushHistoryStack: (h: string) => void;
}

export const useHistoryStackStore = create(
  persist<State & Action>(
    (set) => ({
      historyStack: [],
      pushHistoryStack: (s) =>
        set((state) => {
          // 防止短时间重复添加相同的路由
          if (
            state.historyStack.length &&
            state.historyStack[state.historyStack.length - 1] === s
          ) {
            return state;
          }

          // 防止历史记录超过 10 个
          if (state.historyStack.length >= MAX_HISTORY_STACK_CACHE) {
            return {
              historyStack: [...state.historyStack.slice(1), s],
            };
          }

          return {
            historyStack: [...state.historyStack, s],
          };
        }),
    }),
    { name: CACHE_KEY_ROUTER_HISTORY },
  ),
);
