import dayjs from "dayjs";
import { useOrgInfoStore } from "@/stores/useOrgInfoStore";

export enum OrderStatusEnum {
  // 待付款
  Unpaid = 1,

  // 已取消
  Cancelled = 2,

  // 待入住
  WaitingCheckIn = 3,

  // 今日入住
  CheckInToday = 4,

  // 入住中
  CheckIn = 5,

  // 今日退房
  CheckOutToday = 6,

  // 已退房
  CheckedOut = 7,
}

export const ORDER_STATUS_OPTIONS: Record<
  OrderStatusEnum,
  {
    value: OrderStatusEnum;
    label: string;
    color: string;
    calendarColor: string;
  }
> = {
  [OrderStatusEnum.Unpaid]: {
    value: OrderStatusEnum.Unpaid,
    label: "待付款",
    color: "#DD0000",
    calendarColor: "#DD0000",
  },
  [OrderStatusEnum.Cancelled]: {
    value: OrderStatusEnum.Cancelled,
    label: "已取消",
    color: "#C1C8CD",
    calendarColor: "#C1C8CD",
  },
  [OrderStatusEnum.WaitingCheckIn]: {
    value: OrderStatusEnum.WaitingCheckIn,
    label: "待入住",
    color: "#BED491",
    calendarColor: "#6F9F0A",
  },
  [OrderStatusEnum.CheckInToday]: {
    value: OrderStatusEnum.CheckInToday,
    label: "今日入住",
    color: "#6F9F0A",
    calendarColor: "#FF6100",
  },
  [OrderStatusEnum.CheckIn]: {
    value: OrderStatusEnum.CheckIn,
    label: "入住中",
    color: "#FF6100",
    calendarColor: "#FF6100",
  },
  [OrderStatusEnum.CheckOutToday]: {
    value: OrderStatusEnum.CheckOutToday,
    label: "今日退房",
    color: "#FFAB00",
    calendarColor: "#FF6100",
  },
  [OrderStatusEnum.CheckedOut]: {
    value: OrderStatusEnum.CheckedOut,
    label: "已退房",
    color: "#C1C8CD",
    calendarColor: "#C1C8CD",
  },
};

export const getCurrentTimeZoneDate = () =>
  dayjs.tz(
    dayjs(),
    useOrgInfoStore.getState().data?.org?.timeZone || "Asia/Tokyo",
  );

export const getOrderStatus = ({
  dateStart,
  dateEnd,
  paidAt,
  isCancelled,
}: {
  dateStart: string;
  dateEnd: string;
  paidAt?: string;
  isCancelled: boolean;
}) => {
  // 订单状态的判断的优先级
  // 1. 判断是否已取消 -> 根据 isCancelled 判断(之前是根据 cancelledAt不为空且cancelledAt > 当前时间判断的)
  // 2. 判断是否已付款 -> 根据 paidAt 判断, paidAt存在就是已付款 paidAt 不存在就是未付款
  // 3. 判断入住状态 -> 根据 dateStart 和 dateEnd 判断

  const nowDate = dayjs();

  if (isCancelled) {
    return OrderStatusEnum.Cancelled;
  }

  if (!paidAt) {
    return OrderStatusEnum.Unpaid;
  }

  // 今日入住
  if (nowDate.isSame(dateStart, "day")) {
    return OrderStatusEnum.CheckInToday;
  }

  // 待入住
  if (nowDate.isBefore(dateStart, "day")) {
    return OrderStatusEnum.WaitingCheckIn;
  }

  // 入住中
  if (nowDate.isAfter(dateStart, "day") && nowDate.isBefore(dateEnd, "day")) {
    return OrderStatusEnum.CheckIn;
  }

  // 今日退房
  if (nowDate.isSame(dateEnd, "day")) {
    return OrderStatusEnum.CheckOutToday;
  }

  // 已退房
  if (nowDate.isAfter(dateEnd, "day")) {
    return OrderStatusEnum.CheckedOut;
  }

  return OrderStatusEnum.CheckedOut;
};

export const getOccupancyTime = (time: number) => {
  const h = Math.floor(time / 60);
  const m = time % 60;

  return `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}`;
};
