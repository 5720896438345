import { type Point } from "@api/http_resv/common/common_type";
import { useUpdateEffect } from "ahooks";
import { useMemo, useState } from "react";
import { createGlobalStore } from "hox";
import { useLocation } from "react-router-dom";

export const [useResvInfo] = createGlobalStore(() => {
  const initData = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return {
      addr: searchParams.get("addr"),
      lng: Number(searchParams.get("lng")),
      lat: Number(searchParams.get("lat")),
      start: searchParams.get("start"),
      end: searchParams.get("end"),
      adult: Number(searchParams.get("adult")) || 1,
    };
  }, []);

  const [address, setAddress] = useState<
    { name: string; point: Point } | undefined
  >(() => {
    if (initData.lng && initData.lat) {
      return {
        name: initData.addr || "",
        point: {
          lng: initData.lng,
          lat: initData.lat,
        },
      };
    }
    return undefined;
  });

  const [occupancy, setOccupancy] = useState<
    | {
        startDate: string;
        endDate: string;
      }
    | undefined
  >(() => {
    if (initData.start && initData.end) {
      return {
        startDate: initData.start,
        endDate: initData.end,
      };
    }
    return undefined;
  });

  const [adultCount, setAdultCount] = useState(initData.adult);

  const location = useLocation();

  useUpdateEffect(() => {
    if (
      location.pathname !== "/" &&
      !location.pathname.startsWith("/room") &&
      !location.pathname.startsWith("/order_confirm")
    ) {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    if (address) {
      searchParams.set("addr", address.name);
      searchParams.set("lng", address.point.lng.toString());
      searchParams.set("lat", address.point.lat.toString());
    } else {
      searchParams.delete("addr");
      searchParams.delete("lng");
      searchParams.delete("lat");
    }
    if (occupancy) {
      searchParams.set("start", occupancy.startDate);
      searchParams.set("end", occupancy.endDate);
    } else {
      searchParams.delete("start");
      searchParams.delete("end");
    }
    if (adultCount) {
      searchParams.set("adult", adultCount.toString());
    } else {
      searchParams.delete("adult");
    }
    const search = searchParams.size > 0 ? `?${searchParams.toString()}` : "";

    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}${search}`,
    );
  }, [address, occupancy, adultCount, location]);

  return {
    address,
    setAddress,
    occupancy,
    setOccupancy,
    adultCount,
    setAdultCount,
  };
});
