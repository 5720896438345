import { useMemo, type FC, type ReactNode } from "react";

export const TextHighLight: FC<{
  text: string;
  keyword?: string;
}> = ({ text, keyword }) => {
  const content = useMemo(() => {
    if (!keyword) {
      return text;
    }
    const splits = text.split(keyword);
    const items: ReactNode[] = [splits.shift()];
    splits.forEach((s, index) =>
      items.push(
        <span key={index} className="text-easbnb-brand">
          {keyword}
        </span>,
        s,
      ),
    );
    return items;
  }, [keyword, text]);

  return <span>{content}</span>;
};
