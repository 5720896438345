import { type RoomInDetail } from "@api/http_resv/room/room_type";
import clsx from "clsx";
import { Card } from "@/components";
import { useIsPC } from "@/stores";

interface RoomPresentCardProps {
  data: RoomInDetail;
}

export const RoomPresentCard: React.FC<RoomPresentCardProps> = ({ data }) => {
  const isPC = useIsPC();

  if (!data?.introduction) {
    return null;
  }

  return (
    <Card
      title="房间介绍"
      className={clsx({
        "p-6": isPC,
      })}
    >
      <div className="text-text-666 whitespace-pre-line pt-3 text-sm">
        {data?.introduction}
      </div>
    </Card>
  );
};
