import { useNavigate, generatePath } from "react-router-dom";
import { isInWeChat } from "@/utils/environment";

interface CustomNavigateArgs {
  id?: string;
  state?: Record<string, any>;
  replace?: boolean;
}

export const useCustomNavigate = () => {
  const navigate = useNavigate();

  const customNavigate = (path: string, options?: CustomNavigateArgs) => {
    const { id, state, replace } = options ?? {};
    const customPath = generatePath(id ? `${path}/:id` : path, {
      id,
    });
    navigate(`${customPath}`, {
      replace: replace ?? isInWeChat(),
      state,
    });
  };

  return customNavigate;
};
