import { type FC } from "react";
import dayjs from "dayjs";
import NiceModal from "@ebay/nice-modal-react";
import Calendar from "@/assets/icons/calendar.svg?react";
import {
  DateRangePopup,
  type DateRangePopupProps,
} from "@/components/Popup/DateRangePopup";
import { toYYYYMMDD } from "@/lib/utils";

export interface DateRange {
  startDate: string;
  endDate: string;
}

export interface LodgingDatesProps {
  /** 传 roomId 后，自动开启可用性校验 */
  roomId?: string;
  value?: DateRange;
  onChange?: (value: DateRange) => void;
}

export const HeaderLodgingDates: FC<LodgingDatesProps> = ({
  onChange,
  value,
  roomId,
}) => {
  const showDateRangePopup = async () => {
    const params: DateRangePopupProps = {
      roomId,
      value,
    };
    const res: DateRange = await NiceModal.show(DateRangePopup, params);
    onChange?.(res);
  };

  return (
    <div
      className="flex flex-1 items-center space-x-2 rounded-xl bg-[#F5F7FB] p-3"
      onClick={showDateRangePopup}
    >
      <div className="flex cursor-pointer items-center">
        <Calendar className="mr-3 size-6" />
        {value?.startDate && value?.endDate ? (
          <div className="text-text-1 flex flex-1 items-center justify-between gap-1 text-sm">
            <span>{toYYYYMMDD(dayjs(value.startDate))}</span>
            <span>-</span>
            <span>{toYYYYMMDD(dayjs(value.endDate))}</span>
          </div>
        ) : (
          <div
            className="text-text-4 w-full flex-1 cursor-pointer text-sm"
            onClick={showDateRangePopup}
          >
            选择入离日期
          </div>
        )}
      </div>
    </div>
  );
};
