import { useState, useMemo } from "react";
import { Image, ImagePreview, NavBar } from "react-vant";
import { Helmet } from "react-helmet";
import { useRequest } from "ahooks";
import { RoomService } from "@api/http_resv/room/room_srv";
import { isNil } from "lodash-es";
import clsx from "clsx";
import { useLocation, useParams } from "react-router-dom";
import { useCustomNavigate, useResvInfo, useSwiperData } from "@/hooks";
import { genBigRoomSwiperCoverUrl } from "@/utils/file";
import {
  type ImagesGroupItem,
  type InitImageProps,
} from "@/components/RoomSwiper";
import { genPageTitle } from "@/utils";
import { PATHS } from "@/constants/path";
import { ErrorComp, FixedHeaderWrapper } from "@/components";
import { useIsPC } from "@/stores";

export const RoomPhoto = () => {
  const customNavigate = useCustomNavigate();
  const isPC = useIsPC();
  const params = useParams<{ id?: string }>();
  const id = params.id;
  const location = useLocation();

  const { occupancy, adultCount } = useResvInfo();
  const [active, setActive] = useState("");
  const { data, error } = useRequest(
    async () => {
      const resp = await RoomService.Get({
        id: id!,
        priceQuery: {
          startDate: occupancy?.startDate,
          endDate: occupancy?.endDate,
          adultCount,
        },
      });

      return resp.room.images;
    },
    {
      ready: isNil(location.state),
    },
  );

  const initPhotoData = useMemo(() => {
    if (isNil(location.state?.data)) {
      return data;
    }
    return location.state.data;
  }, [data]);

  const groupPhotoData = useSwiperData(
    (initPhotoData as InitImageProps[]) ?? [],
  ) as ImagesGroupItem[];

  const onTabClick = (item: ImagesGroupItem) => {
    setActive(item.label);
    const el = document.getElementById(item.label);
    if (el) {
      const height = el.offsetTop - 94;
      window.scrollTo({
        top: height,
        behavior: "smooth",
      });
    }
  };

  if (error) {
    return <ErrorComp error={error} />;
  }

  return (
    <div>
      <Helmet>
        <title>{genPageTitle("房间相册")}</title>
      </Helmet>
      {isPC ? (
        <FixedHeaderWrapper
          pageName="房间相册"
          goBack={() => customNavigate(PATHS.ROOM, { id })}
        />
      ) : (
        <NavBar
          title="房间相册"
          leftText="返回"
          placeholder={true}
          zIndex={50}
          onClickLeft={() => {
            customNavigate(PATHS.ROOM, {
              id,
            });
          }}
        />
      )}
      <div className="flex flex-col bg-[#fafafa]">
        {!isPC && (
          <div className="bg-page-bg no-scrollbar fixed z-50 flex h-12 w-full items-center overflow-x-auto px-4">
            <div className="no-scrollbar inline-block w-full space-x-3 overflow-y-hidden whitespace-nowrap">
              {groupPhotoData.map((item: ImagesGroupItem, index) => (
                <div
                  key={item.name}
                  onClick={() => {
                    onTabClick(item);
                  }}
                  className={clsx(
                    "inline-block h-6 rounded-lg px-2 py-0.5 text-sm",
                    active === item.label
                      ? "bg-easbnb-brand text-white"
                      : "text-text-2 bg-[#F0F2F4]",
                    // 第一个元素不给左边距
                    index === 0 && "ml-0",
                  )}
                >
                  {item.name}&nbsp;({item.images.length})
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="mb-4">
          {groupPhotoData.map((item: ImagesGroupItem) => (
            <PhotoItem
              initData={initPhotoData as InitImageProps[]}
              data={item}
              key={item.name}
              isPC={isPC}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const PhotoItem: React.FC<{
  initData: InitImageProps[];
  data: ImagesGroupItem;
  isPC: boolean;
}> = ({ initData, data, isPC }) => {
  return (
    <div
      id={data.label}
      className={clsx(
        isPC ? "top-4 mx-auto w-[80vw] max-w-screen-lg" : "top-12 px-4",
        "bg-page-bg relative grow overflow-auto ",
      )}
    >
      <span>
        {data.name}&nbsp;({data.images.length})
      </span>
      <div className="mb-6 mt-3">
        <div
          className={clsx(isPC ? "gap-4" : "gap-3", "flex w-full flex-wrap ")}
        >
          {data.images.map((item) => (
            <div
              key={item.index}
              className={clsx(
                isPC ? "h-[120px] w-[168px] lg:w-[192px]" : "h-[110px]",
                "rounded-full",
              )}
              style={{
                width: isPC ? "" : "calc(50% - 6px)",
              }}
            >
              <Image
                src={genBigRoomSwiperCoverUrl(item.uri)}
                fit="cover"
                radius="8px"
                // TODO: 有没有办法让图片自动加载
                // lazyload={true}
                onClick={() => {
                  ImagePreview.open({
                    images: initData.map((image) =>
                      genBigRoomSwiperCoverUrl(image.uri),
                    ),
                    startPosition: item.index,
                  });
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
