import { useMemo } from "react";

export const useSafeAreaInsetBottom = () => {
  const safeAreaInsetBottom = useMemo(() => {
    const bottom = parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(
        "--safe-area-inset-bottom",
      ),
    );
    if (bottom > 0) {
      return bottom;
    }
    return 0;
  }, []);

  return safeAreaInsetBottom;
};
