export const MAX_SEARCH_HISTORY_CACHE = 12;

export const MAX_HISTORY_STACK_CACHE = 10;

export const CACHE_KEY_SEARCH_HISTORY = "__CACHE_KEY_SEARCH_HISTORY";

export const CACHE_KEY_ROUTER_HISTORY = "__CACHE_KEY_ROUTER_HISTORY";

export const CACHE_KEY_BRAND_NAME = "__CACHE_KEY_BRAND_NAME";

export const CACHE_KEY_USER_INFO = "__CACHE_KEY_USER_INFO";

export const CACHE_KEY_ORG_INFO = "__CACHE_KEY_ORG_INFO";
