import { create } from "zustand";

import type * as org_srv from "@api/http_resv/org/org_srv";
import { OrgService } from "@api/http_resv/org/org_srv";
import { setBrandName } from "@/utils/cache";
import { BRAND_NAME } from "@/constants/text";

interface StoreState {
  data: org_srv.InfoResp | null;
  fetchOrgInfo: () => Promise<org_srv.InfoResp>;
}
export const useOrgInfoStore = create<StoreState>((set) => ({
  data: null,
  fetchOrgInfo: async () => {
    const resp = await OrgService.Info({});
    setBrandName(resp?.org?.name ?? BRAND_NAME);

    set({ data: resp });
    return resp;
  },
}));
