import React from "react";
import type * as room_type from "@api/http_resv/room/room_type";
import type * as common_type from "@api/http_pms/common/common_type";
import { Image } from "react-vant";
import LocationIcon from "@/assets/icons/location.svg?react";
import { Card } from "@/components/Card";
import { PLACEHOLDER_TEXT } from "@/constants/text";
import { getMoneyText } from "@/utils/money";
import { getRoomInfo } from "@/utils/roomInfo";
import { PATHS } from "@/constants/path";
import { useCustomNavigate } from "@/hooks";

interface DetailRoomCardProps {
  disableLink?: boolean;
  imageUri: string;
  room: room_type.Room;
  quotedPrice?: common_type.CurrencyAmount;
  orderId?: string;
}

export const DetailRoomCard: React.FC<DetailRoomCardProps> = ({
  disableLink = false,
  room,
  quotedPrice,
  imageUri,
  orderId,
  ...props
}) => {
  const roomInfoText = getRoomInfo({
    area: room.roomSize,
    bed: room.singleBedCount,
    doubleBed: room.doubleBedCount,
  });
  const customNavigate = useCustomNavigate();

  const onGoToRoomDetail = (e: React.MouseEvent) => {
    if (disableLink) return;
    customNavigate(PATHS.ROOM, {
      id: room.id,
      state: {
        backPath: PATHS.ORDER,
        orderId,
      },
    });
    e.stopPropagation();
  };

  return (
    <Card className="max-w-screen-lg px-6" {...props}>
      <div className="flex py-3" onClick={onGoToRoomDetail}>
        <div className="mr-4 h-[160px] w-[200px] overflow-hidden rounded-lg">
          <Image src={imageUri} alt="房间图片" fit="cover" />
        </div>
        <div className="flex flex-1 flex-col">
          <div className="text-text-1 mb-3 line-clamp-1 text-ellipsis text-xl font-medium">
            {room.title || PLACEHOLDER_TEXT}
          </div>
          <div className="mb-8">
            {room.roomSize > 0 && (
              <span className="text-text-666 mr-3 text-sm">{roomInfoText}</span>
            )}
            {room.personCapacity > 0 && (
              <span className="text-easbnb-brand text-sm">
                可住{room.personCapacity}人
              </span>
            )}
          </div>
          <span className="text-text-2 mb-5 text-sm">
            {quotedPrice && (
              <div>
                {getMoneyText(quotedPrice.amount, quotedPrice.currency, {
                  hidePlusSign: true,
                })}
              </div>
            )}
          </span>

          {room.address && (
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <LocationIcon className="size-4" />
                <div className="text-text-666 text-sm">{room.address}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};
