import NiceModal, { useModal } from "@ebay/nice-modal-react";
import clsx from "clsx";
import { Popup } from "react-vant";
import { isArray } from "lodash-es";
import { useState, useMemo } from "react";
import dayjs from "dayjs";
import { useRequest } from "ahooks";
import { RoomService } from "@api/http_resv/room/room_srv";
import { Loading } from "../../Loading";
import { CalendarCssWrapper } from "./styled";

import { isHotelIndustryWeekend, toYYYYMMDD } from "@/lib/utils";
import { type DateRange } from "../../LodgingDates";
import { useIsPC } from "@/stores";

export interface DateRangePopupProps {
  value?: DateRange;
  roomId?: string;
}

export const DateRangePopup = NiceModal.create(
  ({ value: initValue, roomId }: DateRangePopupProps) => {
    const modal = useModal();
    const isPC = useIsPC();

    const [newDates, setNewDates] = useState(() => {
      return initValue?.startDate && initValue?.endDate
        ? [
            dayjs(initValue.startDate).toDate(),
            dayjs(initValue.endDate).toDate(),
          ]
        : undefined;
    });

    const nights = useMemo(
      () => (newDates ? dayjs(newDates[1]).diff(dayjs(newDates[0]), "day") : 0),
      [newDates],
    );

    const { data, loading } = useRequest(
      async () => {
        const res = await RoomService.ListRoomDayClose({
          startDate: dayjs().format("YYYY-MM-DD"),
          endDate: dayjs().add(6, "months").format("YYYY-MM-DD"),
          roomId: roomId!,
        });
        return {
          disabledDaysSet: new Set(res.days),
          disabledDays: res.days,
        };
      },
      {
        ready: Boolean(roomId),
      },
    );

    const onSubmit = () => {
      if (canNotBook || !newDates || newDates?.length !== 2) return;
      modal.resolve({
        startDate: toYYYYMMDD(newDates[0]),
        endDate: toYYYYMMDD(newDates[1]),
      });
      void modal.hide();
    };

    const footerRender = () => (
      <div className="flex h-20 w-full items-center justify-between px-2 py-3">
        <div>
          {canNotBook ? (
            <span className="mr-2 text-sm text-[#EC642B]">已选日期不可定</span>
          ) : !newDates?.[0] ? (
            <span className="text-text-1 mr-2 text-sm">请选择入住日期</span>
          ) : !newDates?.[1] ? (
            <span className="text-text-1 mr-2 text-sm">请选择退房日期</span>
          ) : (
            <span className="text-text-1 mr-2 text-sm">
              {nights > 0 ? `已选: ${nights + 1}天${nights}晚` : null}
            </span>
          )}
        </div>

        <div
          onClick={onSubmit}
          className={clsx(
            "flex h-12 w-40 cursor-pointer items-center justify-center rounded-full border text-base font-medium text-white",
            canNotBook || !newDates || newDates?.length !== 2
              ? "border-[#979BAD] bg-[#979BAD]"
              : " border-text-1  hover:bg-text-333 bg-[#3D3D3D]",
          )}
        >
          确定
        </div>
      </div>
    );

    const [minDate, maxDate] = useMemo(
      () => [
        dayjs().startOf("d").toDate(),
        dayjs().add(6, "months").startOf("d").toDate(),
      ],
      [],
    );

    const canNotBook = useMemo(() => {
      if (!roomId || !newDates?.length) return false;
      const start = toYYYYMMDD(newDates[0]);
      const end = toYYYYMMDD(newDates[1]);
      return data?.disabledDays?.some((cur) => cur >= start && cur < end);
    }, [newDates, data]);

    const maxCanBookDay = useMemo(() => {
      if (!roomId || newDates?.length !== 1) return undefined;
      const start = toYYYYMMDD(newDates[0]);
      const target = data?.disabledDays?.find((cur) => cur >= start);
      return target ? dayjs(target).toDate() : undefined;
    }, [newDates, data]);

    return (
      <Popup
        visible={modal.visible}
        closeable={true}
        className={clsx(isPC ? "h-[520px] w-[500px]" : "h-4/5")}
        position={isPC ? "center" : "bottom"}
        round={true}
        destroyOnClose={true}
        onClose={() => {
          modal.reject();
          void modal.hide();
        }}
        onClosed={modal.remove}
      >
        {loading ? (
          <div className="flex h-1/2 w-full items-center justify-center">
            <Loading />
          </div>
        ) : (
          <CalendarCssWrapper
            $canNotBook={canNotBook}
            value={newDates}
            onSelect={(v) => {
              if (!isArray(v)) return;
              setNewDates(v[0] ? v : undefined);
            }}
            defaultValue={newDates}
            poppable={false}
            type="range"
            minDate={minDate}
            maxDate={maxDate}
            showConfirm={false}
            title="选择入离日期"
            footer={footerRender()}
            formatter={(day) => {
              const className = [];
              if (day.type === "start") {
                className.push("check-in");
              } else if (day.type === "end") {
                className.push("check-out");
              }

              // 可用区间范围外，直接不可用
              if (!day.date || day.date < minDate || day.date > maxDate) {
                day.bottomInfo = "关闭";
                day.type = "disabled";
                day.className = className.join(" ");
                return day;
              }

              // 周末红色
              if (isHotelIndustryWeekend(day.date)) {
                className.push("weekend");
              }

              // 对于只选择了开始的状态，之后的日期特殊处理逻辑
              if (newDates?.length === 1 && day.date > newDates[0]!) {
                // 如果前一天是可用的，则当前日期可用
                if (maxCanBookDay && day.date > maxCanBookDay) {
                  day.type = "disabled";
                  day.bottomInfo = "不可用";
                } else {
                  day.type = undefined;
                  day.bottomInfo = undefined;
                }
              }
              // 后台关闭日期标记为禁用
              else if (
                data?.disabledDaysSet?.has(dayjs(day.date).format("YYYY-MM-DD"))
              ) {
                day.type = "disabled";
                day.bottomInfo = "不可用";
              }

              // 范围内手动控制 middle 逻辑，否则禁用日期不会自动加 middle 样式
              if (
                newDates?.length === 2 &&
                day.date >= newDates[0]! &&
                day.date <= newDates[1]!
              ) {
                className.push("rv-calendar__day--middle");
              }

              day.className = className.join(" ");
              return day;
            }}
          />
        )}
      </Popup>
    );
  },
);
