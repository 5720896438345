import { useLocation } from "react-router-dom";
import { NAVIGATION_BAR_ITEMS } from "@/constants/tabBar";
import { cn } from "@/lib/utils";
import { useCustomNavigate } from "@/hooks/useCustomNavigate";
import { FixedBottomWrapper } from "../FixedBottomWrapper";
import { useIsPC, useUserInfoStore } from "@/stores";

export const BottomNavigationBar = () => {
  const isPC = useIsPC();
  const location = useLocation();
  const { pathname } = location;

  const customNavigate = useCustomNavigate();
  const user = useUserInfoStore((s) => s.userInfo);

  const bottomItems = NAVIGATION_BAR_ITEMS.filter((el) =>
    user ? true : !el.needLogin,
  );

  if (isPC) {
    return null;
  }

  return (
    <FixedBottomWrapper className="z-50 mx-auto flex min-h-[70px] max-w-[750px] justify-center rounded-t-[35px] bg-white">
      {bottomItems.map((el) => (
        <div
          key={el.path}
          className="flex flex-1 flex-col items-center pt-3"
          onClick={() => customNavigate(el.path)}
        >
          {pathname === el.path ? el.selectedIcon : el.icon}
          <div
            className={cn(
              "mt-1.5 text-sm",
              pathname === el.path ? "text-text-1" : "text-text-2",
            )}
          >
            {el.label}
          </div>
        </div>
      ))}
    </FixedBottomWrapper>
  );
};
