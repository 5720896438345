import { Helmet } from "react-helmet";
import { Dialog, Toast } from "react-vant";
import NiceModal from "@ebay/nice-modal-react";
import { SignService } from "@api/http_resv/sign/sign_srv";
import type * as sign_srv from "@api/http_resv/sign/sign_srv";
import clsx from "clsx";
import { FixedHeaderWrapper, LoginPopup } from "@/components";
import MePlaceholderAvatar from "@/assets/images/me_placeholder_avatar.webp";
import MeLoginAvatar from "@/assets/images/me_login_avatar.webp";
import CellArrowRightIcon from "@/assets/icons/cell_arrow_right.svg?react";
import PowerOffIcon from "@/assets/icons/power_off.svg?react";
import { genPageTitle } from "@/utils";
import { useUserInfoStore, useOrgInfoStore, useIsPC } from "@/stores";
import { PLACEHOLDER_TEXT } from "@/constants/text";

export const Me = () => {
  const user = useUserInfoStore((s) => s.userInfo);

  const orgInfo = useOrgInfoStore((s) => s.data);
  const removeUserInfo = useUserInfoStore((s) => s.removeUserInfo);
  const avatar = user ? MeLoginAvatar : MePlaceholderAvatar;
  const isPC = useIsPC();

  const onLogin = async () => {
    if (!user) {
      const v = await NiceModal.show(LoginPopup, {});
      if (!v) return;
      Toast.success({ message: "登录成功", duration: 1000 });
    }
  };

  const onLogout = async () => {
    await Dialog.confirm({
      title: "退出登录",
      message: "确定退出登录吗？",
      confirmButtonText: "确定",
      onConfirm: async () => {
        try {
          Toast.loading({
            message: "加载中...",
            duration: 0,
            forbidClick: true,
            overlay: true,
          });

          removeUserInfo();
          await SignService.SignOut({});
          Toast.clear();
        } catch (error) {
          Toast.clear();
        }
      },
      confirmButtonColor: "var(--wd-color-danger)",
    });
  };

  const onContactSupportStaff = () => {
    void Dialog.alert({
      message: orgInfo?.org?.supportText ?? "联系客服信息",
      messageAlign: "left",
      confirmButtonText: "确定",
      confirmButtonColor: "var(--wd-color-easbnb-brand)",
    });
  };

  return (
    <div className="h-full">
      <Helmet>
        <title>{genPageTitle("我的")}</title>
      </Helmet>
      {isPC && <FixedHeaderWrapper onlyTitle={true} />}

      <div className="px-4">
        <User isPC={isPC} user={user} avatar={avatar} onLogin={onLogin} />
        <div className="mx-auto mb-3 max-w-screen-lg rounded-xl bg-white px-3 py-6">
          <div
            className="flex items-center justify-between"
            onClick={onContactSupportStaff}
          >
            <div className="text-text-1 text-sm">联系客服</div>
            <CellArrowRightIcon className="size-[22px]" />
          </div>
        </div>

        {user && (
          <div className="mx-auto max-w-screen-lg">
            <button
              type="button"
              className="flex w-full items-center justify-center space-x-2 rounded-xl bg-white py-3"
              onClick={onLogout}
            >
              <PowerOffIcon className="size-4" />
              <span className="text-sm text-[#F74D36]">退出登录</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const User: React.FC<{
  user: sign_srv.LoginResp["user"] | null | undefined;
  avatar: string;
  isPC: boolean;
  onLogin: () => void;
}> = ({ user, avatar, isPC, onLogin }) => {
  return (
    <div
      className={clsx(
        isPC
          ? "mx-auto mb-3 mt-5 h-[340px] max-w-screen-lg rounded-xl bg-white"
          : " h-[280px]",
        "flex w-full items-center justify-center overflow-y-auto px-4 py-3",
      )}
    >
      <div
        className="flex flex-col items-center justify-center"
        onClick={onLogin}
      >
        <img
          src={avatar}
          className={clsx(isPC ? "mb-7" : "mb-4", "size-24 rounded-xl")}
          alt="头像"
        />
        {user ? (
          <>
            <div className="text-text-1 mb-2 text-xl font-medium">
              {user?.name || PLACEHOLDER_TEXT}
            </div>
            <div className="text-text-2 flex flex-col items-center justify-center text-sm">
              <span>{user?.phoneNumber ? user?.phoneNumber : ""}</span>
              <span>{user.email || PLACEHOLDER_TEXT}</span>
            </div>
          </>
        ) : (
          <>
            <div className="text-text-1 mb-2 text-xl font-medium">
              登录/注册
            </div>
            <div className="text-text-4 text-sm">
              点击登录/注册 享受更多精彩信息
            </div>
          </>
        )}
      </div>
    </div>
  );
};
