import { type OrderInDetail } from "@api/http_resv/order/order_type";
import clsx from "clsx";
import { Card } from "@/components";
import { PLACEHOLDER_TEXT } from "@/constants/text";
import { useIsPC } from "@/stores";

interface PersonInfoCardProps {
  data: OrderInDetail;
}

export const PersonInfoCard: React.FC<PersonInfoCardProps> = ({ data }) => {
  const isPC = useIsPC();

  if (!data?.tenant) {
    return null;
  }

  return (
    <Card
      className={clsx({
        "p-6": isPC,
      })}
      title="联系人信息"
    >
      <div className="flex flex-col space-y-4 pt-3">
        <div className="flex items-start">
          <div className="text-text-666 w-[100px] text-left text-sm">姓名</div>
          <div className="text-text-1 line-clamp-1 flex-1 text-ellipsis text-sm">
            {data.tenant.name || PLACEHOLDER_TEXT}
          </div>
        </div>
        {data.tenant.phoneNumber && (
          <div className="flex items-start">
            <div className="text-text-666 w-[100px] text-left text-sm">
              联系方式
            </div>
            <div className="text-text-1 line-clamp-1 flex-1 text-ellipsis text-sm">
              {data.tenant.countryCode ? `${data.tenant.countryCode} ` : " "}
              {data.tenant.phoneNumber || PLACEHOLDER_TEXT}
            </div>
          </div>
        )}
        <div className="flex items-start">
          <div className="text-text-666 w-[100px] text-left text-sm">
            邮箱地址
          </div>
          <div className="text-text-1 line-clamp-1 flex-1 text-ellipsis text-sm">
            {data.tenant.email || PLACEHOLDER_TEXT}
          </div>
        </div>
        <div className="flex items-start">
          <div className="text-text-666 w-[100px] text-sm">备注信息</div>
          <div className="text-text-1 flex-1 text-sm">
            {data.remark || PLACEHOLDER_TEXT}
          </div>
        </div>
      </div>
    </Card>
  );
};
