import NiceModal from "@ebay/nice-modal-react";
import { type FC } from "react";
import { Toast } from "react-vant";
import ErrorPageIcon from "@/assets/icons/error_page.svg?react";
import RefreshPageIcon from "@/assets/icons/refresh_page.svg?react";
import { LoginPopup } from "@/components";
import { PATHS } from "@/constants/path";
import { useCustomNavigate } from "@/hooks/useCustomNavigate";

export const ErrorComp: FC<{ refresh?: () => void; error?: Error }> = ({
  refresh = () => window.location.reload(),
  error,
}) => {
  const customNavigate = useCustomNavigate();

  const onLogin = async () => {
    await NiceModal.show(LoginPopup, {});

    refresh();
  };
  const getErrorMessage = (pageError?: Error) => {
    if (pageError?.message === "webapi_status_401") {
      return "当前未登录，请登录后重试";
    }
    if (pageError?.message === "webapi_status_403") {
      return "当前无账号权限，请重新登录再试";
    }
    return "当前服务出错，请重试";
  };

  return (
    <div className="absolute left-1/2 top-1/2 flex h-full -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center bg-[#FAFAFA]">
      <ErrorPageIcon />
      <span className="text-text-3 mt-4 text-sm font-normal">
        {getErrorMessage(error)}
      </span>

      <div className="mt-12 flex flex-row justify-center gap-4">
        <button
          onClick={() => customNavigate(PATHS.HOME)}
          type="button"
          className="text-text-1 h-12 w-40 cursor-pointer rounded-full border border-[#B8BCCB] bg-white px-10 py-3 text-base font-medium hover:bg-[#F5F7F9]"
        >
          去首页
        </button>
        {error?.message === "webapi_status_401" && (
          <button
            type="button"
            onClick={onLogin}
            className="hover:bg-text-333 h-12 w-40 cursor-pointer rounded-full bg-[#3D3D3D] px-10 py-3 text-base font-medium text-white"
          >
            重新登录
          </button>
        )}
      </div>
      <div className="text-easbnb-brand mt-[180px] flex items-center justify-center gap-2 text-sm font-normal">
        <RefreshPageIcon />
        <span
          className="cursor-pointer underline"
          onClick={() => {
            Toast.loading({
              message: "重试中...",
              duration: 500,
            });
            refresh();
          }}
        >
          刷新重试
        </span>
      </div>
    </div>
  );
};
