import { Calendar } from "react-vant";
import { styled } from "styled-components";
import canBookingCheckInUrl from "@/assets/icons/canbooking_checkin.svg?url";
import canBookingCheckOutUrl from "@/assets/icons/canbooking_checkout.svg?url";
import cantBookingCheckInUrl from "@/assets/icons/cantbooking_checkin.svg?url";
import cantBookingCheckOutUrl from "@/assets/icons/cantbooking_checkout.svg?url";

export const CalendarCssWrapper = styled(Calendar)<{ $canNotBook?: boolean }>`
  .weekend {
    color: #ec642b;
  }

  --rv-calendar-range-middle-color: ${(p) =>
    p.$canNotBook ? "#ec642b" : "#6f9f0a"};

  .rv-calendar__day--middle {
    color: var(--rv-calendar-range-middle-color);
  }
  .rv-calendar__day--middle::after {
    background-color: var(--rv-calendar-range-middle-color);
  }
  .check-out.rv-calendar__day--disabled.rv-calendar__day--middle::after {
    background-color: transparent !important;
  }

  .rv-calendar__day--disabled {
    color: var(--rv-calendar-day-disabled-color) !important;
  }

  .check-in {
    background-image: ${(c) =>
      c.$canNotBook
        ? `url("${cantBookingCheckInUrl}")`
        : `url("${canBookingCheckInUrl}")`} !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-color: ${(c) =>
      c.$canNotBook ? `#ec642b1a` : `#6f9f0a1a`} !important;
    color: transparent !important;
  }
  .check-out {
    background-image: ${(c) =>
      c.$canNotBook
        ? `url("${cantBookingCheckOutUrl}")`
        : `url("${canBookingCheckOutUrl}")`} !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-color: ${(c) =>
      c.$canNotBook ? `#ec642b1a` : `#6f9f0a1a`} !important;
    color: transparent !important;
  }
`;
