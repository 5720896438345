import {
  type FC,
  forwardRef,
  useRef,
  useState,
  useMemo,
  useImperativeHandle,
} from "react";
import { Image, Swiper, type SwiperInstance } from "react-vant";
import type * as common_type from "@api/http_pms/common/common_type";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import NoPhoto from "@/assets/icons/no_photo.svg?react";
import { useCustomNavigate, useSwiperData, type SwiperDataItem } from "@/hooks";
import { PATHS } from "@/constants/path";
import { genBigRoomSwiperCoverUrl, makeStorageUrl } from "@/utils/file";
import { useIsPC } from "@/stores";
import SwiperLeftIcon from "@/assets/icons/swiper_left.svg?react";
import SwiperRightIcon from "@/assets/icons/swiper_right.svg?react";
import AllowRightIcon from "@/assets/icons/allow_right.svg?react";

export interface InitImageProps {
  uri: string;
  rank: number;
  partition: common_type.PartitionKind;
  index?: number;
}

export interface RoomSwiperProps {
  value: InitImageProps[];
}

export interface ImagesGroupItem {
  label: string;
  name: string;
  images: InitImageProps[];
}

export const RoomSwiper: FC<RoomSwiperProps> = ({ value }) => {
  const swiperRef = useRef<SwiperInstance>(null);

  const params = useParams<{ id?: string }>();
  const id = params.id;
  const swiperData = useSwiperData(value);
  const customNavigate = useCustomNavigate();
  const isPC = useIsPC();

  const onRoomPhoto = () => {
    customNavigate(PATHS.ROOM_PHOTO, {
      id,
      state: {
        data: value,
      },
    });
  };

  if (!swiperData.length) {
    return (
      <div className="flex h-[300px] w-full items-center justify-center bg-[#F2F0F4]">
        <NoPhoto />
        <span className="text-text-3 absolute bottom-1/4 translate-y-1/2 text-sm">
          暂无图片
        </span>
      </div>
    );
  }

  return (
    <>
      {isPC ? (
        <div
          className={clsx(
            value.length > 4 ? "h-[474px] " : "h-[356px]",
            "w-full bg-white py-4",
          )}
        >
          <div className="mx-auto w-[640px]">
            <SwiperCore
              value={value}
              ref={swiperRef}
              swiperData={swiperData}
              onRoomPhoto={onRoomPhoto}
            />
          </div>
          {value.length > 4 && (
            <div className="mx-auto w-[80vw] max-w-screen-lg">
              <SwiperPhoto
                value={value}
                ref={swiperRef}
                swiperData={swiperData}
                onRoomPhoto={onRoomPhoto}
              />
            </div>
          )}
        </div>
      ) : (
        <SwiperCore
          value={value}
          ref={swiperRef}
          swiperData={swiperData}
          onRoomPhoto={onRoomPhoto}
        />
      )}
    </>
  );
};

interface SwiperCoreProps {
  value: InitImageProps[];
  swiperData: SwiperDataItem[];
  onRoomPhoto: () => void;
}

const SwiperCore = forwardRef<SwiperInstance, SwiperCoreProps>(
  ({ value, swiperData, onRoomPhoto }, ref) => {
    const swiperRef = useRef<SwiperInstance>(null);
    useImperativeHandle(ref, () => swiperRef.current!);

    const [activeTab, setActiveTab] = useState(0);
    const isPC = useIsPC();

    const handleSwiperTabChange = (
      imgGroup: SwiperDataItem,
      swiperTabIdx: number,
    ) => {
      setActiveTab(swiperTabIdx);
      swiperRef?.current?.swipeTo(imgGroup.images?.[0]?.index ?? 0);
    };

    const handleSwiperChange = (swiperIndex: number) => {
      const active = swiperData.findIndex((swiper) =>
        swiper.images?.some((item) => item.index === swiperIndex),
      );
      setActiveTab(active);
    };

    return (
      <div className="relative">
        {isPC && (
          <>
            <SwiperLeftIcon
              className="absolute left-[-50px] top-[146px] size-6 cursor-pointer"
              onClick={() => {
                swiperRef?.current?.swipePrev();
                handleSwiperChange(
                  swiperRef?.current?.activeIndex as unknown as number,
                );
              }}
            />
            <SwiperRightIcon
              className="absolute right-[-50px] top-[146px] size-6 cursor-pointer"
              onClick={() => {
                swiperRef?.current?.swipeNext();
                handleSwiperChange(
                  swiperRef?.current?.activeIndex as unknown as number,
                );
              }}
            />
          </>
        )}
        <Swiper
          className={clsx(
            isPC ? "h-[320px] rounded-xl" : "h-[300px]",
            " w-full",
          )}
          ref={swiperRef}
          onChange={handleSwiperChange}
          // autoplay={10000}
          indicator={(total, current) => (
            <div>
              <div className="absolute right-4 top-4 rounded-full bg-black/45 px-2 py-1 text-xs text-white">
                {current + 1}/{total}
              </div>
              <div className="absolute bottom-3 left-1/2 flex -translate-x-1/2 items-center justify-between space-x-1 rounded-full bg-black/45 text-xs text-white">
                {swiperData.map((item, index) => (
                  <div
                    key={item.name}
                    className={clsx(
                      "mx-1 my-0.5 w-12 cursor-pointer px-2 py-1",
                      activeTab === index &&
                        "flex items-center justify-center overflow-hidden rounded-full bg-white text-black",
                    )}
                    onClick={() => handleSwiperTabChange(item, index)}
                  >
                    {item.name}
                  </div>
                ))}

                <div className="flex items-center  justify-center">
                  <b className="mr-3 text-white/45">|</b>
                  <div
                    className="flex w-12 cursor-pointer items-center gap-1"
                    onClick={onRoomPhoto}
                  >
                    相册 <AllowRightIcon className="size-3" />
                  </div>
                </div>
              </div>
            </div>
          )}
        >
          {value.map((item) => (
            <Swiper.Item key={item.uri}>
              <Image
                lazyload={true}
                src={genBigRoomSwiperCoverUrl(item.uri)}
                fit="cover"
              />
            </Swiper.Item>
          ))}
        </Swiper>
      </div>
    );
  },
);

interface SwiperPhotoProps {
  value: InitImageProps[];
  swiperData: SwiperDataItem[];
  onRoomPhoto: () => void;
}
const SwiperPhoto = forwardRef<SwiperInstance, SwiperPhotoProps>(
  ({ value, swiperData, onRoomPhoto }, ref) => {
    const data: InitImageProps[] = useMemo(() => {
      const result: InitImageProps[] = [];

      const addImages = (index: number = 0) => {
        if (result.length >= 5) return;

        swiperData.forEach((item: SwiperDataItem) => {
          if (result.length < 5 && item?.images?.[index]) {
            result.push(item.images[index]);
          }
        });

        addImages(index + 1);
      };

      addImages();
      return result;
    }, []);

    return (
      <div className="mx-auto mt-3 flex gap-4">
        {data.map((item, index) => (
          <div
            className={clsx(
              index === 4 && "relative inline-block",
              "cursor-pointer",
            )}
            key={index}
            onClick={() => {
              if (index === 4) return;

              (ref as React.MutableRefObject<SwiperInstance>)?.current?.swipeTo(
                item.index ?? 0,
              );
            }}
          >
            <img
              src={makeStorageUrl(item.uri)}
              className={clsx(
                index === 4 && "bg-black",
                "block h-[110px] w-[192px] rounded-xl object-cover",
              )}
            />
            {index === 4 && (
              <div className="absolute inset-0 rounded-xl bg-black/45">
                <span
                  onClick={onRoomPhoto}
                  className="absolute left-1/2 top-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 cursor-pointer items-center justify-center gap-1 text-sm font-normal text-white"
                >
                  共{value.length}张 <AllowRightIcon className="size-3" />
                </span>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  },
);
