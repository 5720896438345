/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";
import { type Area, type Currency } from "../../http_pms/const/const_enum";
import {
  type WelcomeKind,
  type PayChannel,
  type PopularLocation,
} from "./org_type";

export namespace OrgService {
  // 获取微店信息 直接从host,没有就报错
  export function Info(req: InfoReq, config?: any) {
    return webapi.post<InfoResp>("/api/v1/OrgService_Info", req, config);
  }
}
export interface InfoReq {}

export interface InfoResp {
  // 关闭之后返回nil
  org?: Org;
}

export interface Org {
  area: Area;
  // 币种
  currency: Currency;
  // 时区
  timeZone: string;
  // 名称
  name: string;
  // 背景图
  mainImage: string;
  // 欢迎语
  welcomeText: string;
  // 欢迎语图
  welcomeImage: string;
  // 欢迎语类型
  welcomeKind: WelcomeKind;
  // 联系客服
  supportText: string;
  // 支付渠道
  payChannels: PayChannel[];
  // 热门地点
  locations: PopularLocation[];
}
