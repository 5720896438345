import React, { useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";
import { useEventListener } from "ahooks";
import clsx from "clsx";
import DropdownIcon from "@/assets/icons/dropdown_menu.svg?react";
import GoBackIcon from "@/assets/icons/goback.svg?react";
import { NAVIGATION_BAR_ITEMS } from "@/constants/tabBar";
import { useUserInfoStore } from "@/stores";
import { useCustomNavigate } from "@/hooks";
import { getBrandName } from "@/utils/cache";
import HeaderLoginIcon from "@/assets/icons/header_login.svg";
import HeaderUnLoginIcon from "@/assets/icons/header_unlogin.svg";

interface FixedHeaderWrapperProps {
  pageName?: string;
  goBack?: () => void;
  onlyTitle?: boolean;
  children?: React.ReactNode;
  type?: "normal" | "compact";
}

export const FixedHeaderWrapper: React.FC<FixedHeaderWrapperProps> = ({
  children,
  type = "normal",
  pageName,
  goBack,
  onlyTitle,
  ...props
}: FixedHeaderWrapperProps) => {
  const user = useUserInfoStore((s) => s.userInfo);
  const customNavigate = useCustomNavigate();
  const ref = useRef(null);
  const headerItems = NAVIGATION_BAR_ITEMS.filter((el) =>
    user ? true : !el.needLogin,
  );
  const [dropdownMenuVisible, setDropdownMenuVisible] = useState(false);
  const avatar = user ? HeaderLoginIcon : HeaderUnLoginIcon;

  const [isLarge, setIsLarge] = useState(() => {
    return window.innerWidth > 1024;
  });

  useEventListener("resize", () => {
    setIsLarge(window.innerWidth > 1024);
  });

  const title = getBrandName();

  return (
    <div
      ref={ref}
      className={clsx(
        "sticky top-0 z-50 flex h-16 w-full flex-none items-center border-b border-[#E9F0FF] bg-white px-6 py-4",
      )}
      {...props}
    >
      <div className="mx-auto flex w-[80vw] min-w-[720px] max-w-screen-lg flex-row items-center justify-between">
        <div className="flex  items-center">
          {!onlyTitle && (
            <GoBackIcon
              className="mr-4 flex size-6 cursor-pointer items-center justify-center hover:bg-[#EEE]"
              onClick={goBack}
            />
          )}
          {type !== "compact" && (
            <div>
              <span
                className={clsx(
                  onlyTitle ? "font-medium text-black" : "text-text-666",
                  "cursor-pointer",
                )}
                onClick={() => customNavigate("/")}
              >
                {title ?? ""}
              </span>
              {pageName && !onlyTitle && <span>&nbsp;/&nbsp;{pageName}</span>}
            </div>
          )}
        </div>
        {children}
        {type === "compact" ? (
          <>
            {!isLarge ? (
              <DropdownIcon
                className="ml-4 cursor-pointer"
                onClick={() => setDropdownMenuVisible(!dropdownMenuVisible)}
              />
            ) : (
              <HeaderNav data={headerItems} avatar={avatar} />
            )}
          </>
        ) : (
          <HeaderNav data={headerItems} avatar={avatar} />
        )}

        {dropdownMenuVisible &&
          !isLarge &&
          createPortal(
            <DropdownMenu
              data={headerItems}
              callback={setDropdownMenuVisible}
            />,
            document.body,
          )}
      </div>
    </div>
  );
};

const DropdownMenu: React.FC<{
  data: typeof NAVIGATION_BAR_ITEMS;
  callback: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ data, callback }) => {
  const customNavigate = useCustomNavigate();
  const location = useLocation();
  const { pathname } = location;
  return (
    <div className="fixed top-[64px] z-50 w-full space-y-6 bg-white px-6 py-9 lg:top-[88px]">
      {data.map((el) => (
        <div
          className={clsx(
            pathname === el.path ? "text-easbnb-brand" : "text-text-1",
            "cursor-pointer",
          )}
          onClick={() => {
            customNavigate(el.path);
            callback(false);
          }}
          key={el.path}
        >
          {el.label}
        </div>
      ))}
    </div>
  );
};

const HeaderNav: React.FC<{
  data: typeof NAVIGATION_BAR_ITEMS;
  avatar: string;
}> = ({ data, avatar }) => {
  const customNavigate = useCustomNavigate();
  const location = useLocation();
  const { pathname } = location;
  return (
    <div className="ml-4 flex min-w-[180px] items-center gap-9 font-medium">
      {data.map((el) => (
        <div
          className={clsx(
            pathname === el.path ? "text-easbnb-brand" : "text-text-1",
            "cursor-pointer",
          )}
          onClick={() => customNavigate(el.path)}
          key={el.path}
        >
          {el.headerType === "icon" ? (
            <img src={avatar} alt="头像" />
          ) : (
            el.label
          )}
        </div>
      ))}
    </div>
  );
};
