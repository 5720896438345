import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import NiceModal from "@ebay/nice-modal-react";
import { HoxRoot } from "hox";
import { useRequest } from "ahooks";
import CloseStoreIcon from "@/assets/icons/close_store.svg?react";
import {
  Loading,
  BottomNavigationBarRouterLayout,
  ErrorComp,
} from "@/components";
import { Home } from "./Home";
import { OrderDetail, OrderList, OrderAgreement, OrderConfirm } from "./Order";
import { Me } from "./Me";
import { PATHS } from "@/constants/path";
import { Pay } from "./Pay";
import { RoomList, RoomDetail, RoomPhoto } from "./Room";
import { useOrgInfoStore, useUserInfoStore } from "@/stores";
import { PayResult } from "./Pay/Result";

const router = createBrowserRouter([
  {
    element: (
      <HoxRoot>
        <NiceModal.Provider>
          <Outlet />
        </NiceModal.Provider>
      </HoxRoot>
    ),
    errorElement: <ErrorComp />,
    children: [
      {
        path: `${PATHS.ORDER_CONFIRM}/:id`,
        element: <OrderConfirm />,
      },
      {
        path: `${PATHS.ORDER}/:id`,
        element: <OrderDetail />,
      },
      {
        path: `${PATHS.ORDER_AGREEMENT}/:id`,
        element: <OrderAgreement />,
      },
      {
        path: `${PATHS.PAY}/:id`,
        element: <Pay />,
      },
      {
        path: `${PATHS.PAY_RESULT}/:id`,
        element: <PayResult />,
      },
      {
        path: `${PATHS.ROOM}/:id`,
        element: <RoomDetail />,
      },
      {
        path: PATHS.ROOM_SEARCH,
        element: <RoomList />,
      },
      {
        path: `${PATHS.ROOM_PHOTO}/:id`,
        element: <RoomPhoto />,
      },
      {
        path: PATHS.HOME,
        element: <BottomNavigationBarRouterLayout />,
        children: [
          {
            path: PATHS.HOME,
            element: <Home />,
          },
          {
            path: PATHS.ORDER,
            element: <OrderList />,
          },
          {
            path: PATHS.ME,
            element: <Me />,
          },
        ],
      },
    ],
  },
]);

function App() {
  const { loading, data, error } = useRequest(async () => {
    await useUserInfoStore.getState().fetchUserInfo();
    return await useOrgInfoStore.getState().fetchOrgInfo();
  });

  if (loading) {
    return (
      <div className="flex size-full items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (error) {
    return <ErrorComp error={error} />;
  }

  if (!data?.org) {
    return (
      <div className="flex h-4/5 flex-col items-center justify-center bg-[#FAFAFA]">
        <CloseStoreIcon />
        <span className="text-text-3 text-sm font-normal">该店铺不存在</span>
      </div>
    );
  }

  return <RouterProvider router={router} />;
}

export default App;
