/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";
import { type Point, type PriceQuery } from "../common/common_type";
import { type RoomInList, type RoomInDetail } from "./room_type";

export namespace RoomService {
  // 搜索地理位置 最多20条
  export function ListLocation(req: ListLocationReq, config?: any) {
    return webapi.post<ListLocationResp>(
      "/api/v1/RoomService_ListLocation",
      req,
      config,
    );
  }

  // 房间搜索
  export function ListRoom(req: ListRoomReq, config?: any) {
    return webapi.post<ListRoomResp>(
      "/api/v1/RoomService_ListRoom",
      req,
      config,
    );
  }

  // 房间关闭列表
  export function ListRoomDayClose(req: ListRoomDayCloseReq, config?: any) {
    return webapi.post<ListRoomDayCloseResp>(
      "/api/v1/RoomService_ListRoomDayClose",
      req,
      config,
    );
  }

  // 房间详情
  export function Get(req: GetReq, config?: any) {
    return webapi.post<GetResp>("/api/v1/RoomService_Get", req, config);
  }
}
export interface ListLocationReq {
  keyword: string;
}

// This the module of ListLocationResp
export namespace ListLocationResp {
  export interface Location {
    name: string;
    // 分类
    locationKind: LocationKind;
    address: string;
    point: Point;
  }
}
export interface ListLocationResp {
  locations: ListLocationResp.Location[];
}

export interface ListRoomReq {
  priceQuery: PriceQuery;
  point?: Point;
  // 当前页码 @uint32
  current: number;
  // 每页数量 @uint32
  pageSize: number;
}

export interface ListRoomResp {
  rooms: RoomInList[];
  // 当前页码 @uint32
  current: number;
  // 每页数量 @uint32
  pageSize: number;
  // 总数 @uint32
  total: number;
}

export interface GetReq {
  id: string;
  priceQuery: PriceQuery;
}

export interface GetResp {
  room: RoomInDetail;
}

export interface ListRoomDayCloseReq {
  // 房间id列表
  roomId: string;
  // 查询房态日期范围的起始日 后端限制最大不超过6个月
  startDate: string;
  // 查询房态日期范围的结束日
  endDate: string;
}

export interface ListRoomDayCloseResp {
  days: string[];
}
export enum LocationKind {
  // 地点
  Location = 0,
  // 地铁
  Subway = 1,
  // bus
  Bus = 2,
  // 设施
  Facility = 3,
}
