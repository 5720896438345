import { type FC } from "react";
import clsx from "clsx";
import { Toast } from "react-vant";
import StepperAdd from "@/assets/icons/stepper_add.svg?react";
import StepperMinus from "@/assets/icons/stepper_minus.svg?react";
import DisabledStepperAdd from "@/assets/icons/disabled_stepper_add.svg?react";
import DisabledStepperMinus from "@/assets/icons/disabled_stepper_minus.svg?react";
import People from "@/assets/icons/people.svg?react";

export interface TenantCountProps {
  value: number;
  onChange?: (value: number) => void;
  maxValue?: number;
  className?: string;
}

export const TenantCount: FC<TenantCountProps> = ({
  value = 1,
  onChange,
  maxValue,
  className,
}) => {
  return (
    <div
      className={clsx(
        "border-b-text-4/20 flex items-center justify-between py-3",
        className,
      )}
    >
      <div className="flex items-center space-x-3">
        <People className="size-6" />
        <div className="text-text-666 text-sm">房客人数</div>
      </div>

      <div className="flex items-center">
        {value === 1 ? (
          <DisabledStepperMinus className="size-6" />
        ) : (
          <StepperMinus
            className="size-6 cursor-pointer"
            onClick={() => value > 1 && onChange?.(value - 1)}
          />
        )}
        <div className="flex min-w-12 justify-center">{value}</div>
        {maxValue && value >= maxValue ? (
          <DisabledStepperAdd
            onClick={() => Toast(`该房间最多容纳${maxValue}名房客`)}
            className="size-6"
          />
        ) : (
          <StepperAdd
            className="size-6 cursor-pointer"
            onClick={() =>
              (!maxValue || value < maxValue) && onChange?.(value + 1)
            }
          />
        )}
      </div>
    </div>
  );
};
