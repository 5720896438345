import ReactDOM from "react-dom/client";
import { subscribe } from "pubsub-js";
import { Toast } from "react-vant";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { AutoLoginKind } from "@api/http_resv/sign/sign_srv.ts";
import App from "./pages/App.tsx";
import "./index.css";
import { useUserInfoStore } from "./stores/useUserInfoStore.ts";
import { isInWeChat } from "./utils/environment.ts";
import { getRedirectUrl } from "./utils/url.ts";
import { ErrorBoundary } from "./components/index.ts";

dayjs.extend(utc);
dayjs.extend(timezone);

subscribe("webapi_net_err", (_, error: string) => {
  Toast.fail(error);
});

subscribe("webapi_status_401", (_, error: string) => {
  // 401 状态，移除store中的用户信息
  useUserInfoStore.getState().removeUserInfo();
});

const searchParams = new URLSearchParams(window.location.search);
const autoLoginKind = searchParams.get("autoLoginKind");
const autoLoginCode = searchParams.get("code");

if (autoLoginKind === AutoLoginKind.Wechat.toString() && autoLoginCode) {
  useUserInfoStore
    .getState()
    .setAutoLogin(Number(autoLoginKind), autoLoginCode);
} else if (isInWeChat()) {
  const APPID = "wx797bf7691b830970";
  const redirURL = new URL(window.location.href);
  redirURL.searchParams.set("autoLoginKind", AutoLoginKind.Wechat.toString());

  const refreshUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${getRedirectUrl(redirURL.toString())}&response_type=code&scope=snsapi_base#wechat_redirect`;
  window.location.href = refreshUrl;
  throw new Error("goto wx login");
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
);
