import { PLACEHOLDER_TEXT } from "@/constants/text";

export const getRoomInfo = ({
  area,
  bed,
  doubleBed,
}: {
  area?: number;
  bed?: number;
  doubleBed?: number;
}) => {
  // 面积
  const areaText = `${area || PLACEHOLDER_TEXT}㎡`;
  // 单人床
  const bedText = bed ? `${bed}张单人床` : "";
  // 双人床
  const doubleBedText = doubleBed ? `${doubleBed}张双人床` : "";

  // 单人床 双人床 数量为0或者不存在的不显示
  const roomInfoText = [areaText, bedText, doubleBedText]
    .filter((el) => Boolean(el))
    .join(" · ");

  return roomInfoText;
};

export const getDistanceInfo = (distanceNum: number | string) => {
  if (typeof distanceNum === "string" || !distanceNum) {
    return "";
  }
  let distanceText;

  const distanceNumKm = distanceNum / 1000;

  if (distanceNumKm < 1) {
    distanceText = `${distanceNum}m`;
  } else if (distanceNumKm >= 1 && distanceNumKm < 100) {
    distanceText = `${distanceNumKm.toFixed(1)}km`;
  } else {
    distanceText = `100km+`;
  }

  return distanceText;
};
