import { getStorageUrl } from "./url";

const storageBaseUrl = getStorageUrl();

const imageResizeMap = {
  f: 0, // 缩放到指定尺寸
  l: 1, // 保持长宽比，缩放到长边尺寸，会有留白
  s: 2, // 保持长宽比，缩放到短边尺寸，多余裁剪
};

/**
 * 获取文件名后缀
 * @param filename 文件名
 * @returns 文件名后缀（不包括点），如果没有后缀则返回空字符串
 */
export const getFileExtension = (filename: string): string => {
  const lastDotIndex = filename.lastIndexOf(".");
  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return ""; // 没有后缀或点在第一个字符位置
  }
  return filename.substring(lastDotIndex + 1);
};

export const makeStorageUrl = (uri: string) => {
  if (uri.match(/^(blob:|(https?:)?\/\/)/)) {
    return uri;
  }
  return `${storageBaseUrl}${uri}`;
};

export const makeImageUrl = (
  uri: string,
  w: number,
  h: number,
  r: "f" | "l" | "s",
) => {
  if (uri.match(/^(blob:|(https?:)?\/\/)/)) {
    return uri;
  }
  return `${storageBaseUrl}${uri}?w=${w}&h=${h}&r=${imageResizeMap[r]}&f=webp`;
};
export const genOrderRoomCoverUrl = (uri: string) =>
  makeImageUrl(uri, 84, 84, "f");

export const genSmallRoomCoverUrl = (uri: string) =>
  makeImageUrl(uri, 358, 200, "s");

export const genBigRoomSwiperCoverUrl = (uri: string) =>
  makeImageUrl(uri, 375, 300, "s");
