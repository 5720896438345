/** @format */

// This is code generated automatically by the proto2api, please do not modify

export enum Area {
  // 未知
  UnknownArea = 0,
  // 美国
  US = 1,
  // 日本
  JP = 2,
  // 中国
  CN = 3,
}
export enum Language {
  // 英语
  en = 0,
  // 日本语
  ja = 1,
  // 中文
  zh_CN = 2,
}
export enum Currency {
  // 未知
  UnknownCurrency = 0,
  // 美元
  USD = 1,
  // 日元
  JPY = 2,
  // 人民币
  CNY = 3,
}
// 颜色枚举
export enum Color {
  Color_0 = 0,
  Color_1 = 1,
  Color_2 = 2,
  Color_3 = 3,
  Color_4 = 4,
  Color_5 = 5,
  Color_6 = 6,
  Color_7 = 7,
}
// OTA平台
export enum OtaPlatformKind {
  // 未知
  UnknownOtaPlatform = 0,
  // 爱彼迎
  Airbnb = 1,
  // 缤客
  Booking = 2,
  // 携程
  Ctrip = 3,
  // Trip
  Trip = 4,
  // Expedia
  Expedia = 5,
  // Agoda
  Agoda = 6,
  // vrbo
  Vrbo = 7,
  // rakuten travel
  RakutenTravel = 8,
  // Jalan
  Jalan = 9,
  // 美团
  Meituan = 10,
  // 途家
  Tujia = 11,
  // 小猪
  Xiaozhu = 12,
  // 木鸟
  Muniao = 13,
  // 飞猪
  Fliggy = 14,
  // 抖音
  Douyin = 15,
  // 小红书
  Xiaohongshu = 16,
  // 微店
  Reservation = 100,
}
// 价格浮动类型
export enum PriceFloatKind {
  // 未知
  UnknownPriceFloatKind = 0,
  // 固定值
  Fixed = 1,
  // 金额额浮动
  Price = 2,
  // 比例浮动
  Percent = 3,
}
// 实体枚举
export enum EntityKind {
  // 未知
  UnknownEntityKind = 0,
  // 备注
  Remark = 1,
  // 标签
  Tag = 2,
  // 图片
  Image = 3,
  // 房间
  Room = 4,
  // 活动
  Activity = 5,
  // 账单
  Bill = 6,
  // 钱包
  Wallet = 7,
  // 任务
  Mission = 8,
  // 操作日志
  OperateLog = 9,
  // 组织
  Org = 10,
  // ota账号
  OtaAccount = 11,
  // 房客
  Tenant = 12,
  // 订单
  Order = 13,
  // 公告
  Notice = 14,
  // 附件
  Attachment = 15,
  // 押金
  Deposit = 16,
  // 分组
  RoomGroup = 17,
  // 用户
  User = 18,
  // 房天
  RoomDay = 19,
  // ImConv
  Conv = 20,
  // 微店背景图
  ResvBackground = 21,
  // 微店协议
  ResvContract = 22,
}
export enum ContentKind {
  // 文本
  Text = 0,
  // Md
  Markdown = 1,
  // 富文本
  Rich = 2,
}
export enum PayKind {
  UnknownPayKind = 0,
  // 支付宝
  AliPay = 1,
  // 微信
  WechatPay = 2,
  // 信用卡
  Credit = 3,
}
