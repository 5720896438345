import { isInteger } from "lodash-es";
import type * as const_enum from "@api/http_pms/const/const_enum";
import { CURRENCY_OPTION_MAP } from "@/constants/options";
import { checkIsPositiveNumber } from "./checkIsPositiveNumber";

/** 10000 -> 1000000 */
export const showMoneyToApiMoney = (str: string, hasDec = false) => {
  str = str
    .replace(/[^\d.+-]+/g, "")
    .replace(/(?!^)[+-]/g, "")
    .replace(/(\.\d*?)\./g, "$1");
  return Math.round(Number(str) * (hasDec ? 100 : 1));
};

/** 1000000 -> 10000 / 1000000 -> 10000.00 */
export const apiMoneyToShowMoney = (amount: number, hasDec = false) => {
  if (
    typeof amount !== "number" ||
    Number.isNaN(amount) ||
    !Number.isFinite(amount)
  ) {
    return "0";
  }
  const output = Math.round(amount) / (hasDec ? 100 : 1);
  return output.toFixed(hasDec || !isInteger(output) ? 2 : 0);
};

/** 1000000 -> 10,000 /  1000000 -> 10,000.00 */
export const formatCommaMoney = (value: string | number, hasDec = false) => {
  if (value === "") {
    return "";
  }
  const amount = apiMoneyToShowMoney(Number(value), hasDec);
  return amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

interface OptionsGetMoneyText {
  /** 正数隐藏符号 */
  hidePlusSign?: boolean;

  /** 是否显示 JPY 的符号 */
  hideLabel?: boolean;
  /** 是否隐藏货币 ¥ 符号 */
  hideSymbol?: boolean;

  /** 强制隐藏小数显示 */
  hideDec?: boolean;
  /** 隐藏.00 */
  hide00Dec?: boolean;
}

export const getMoneyText = (
  /** 金额 */
  amount: number,
  /** 币种 */
  currency: const_enum.Currency,
  /** 附加参数（必填，即使没有，也要显式传 {} ） */
  opts: OptionsGetMoneyText,
) => {
  const currencyObj = CURRENCY_OPTION_MAP[currency];

  if (!currencyObj || Number.isNaN(amount)) {
    return "【数据异常】";
  }

  const {
    hidePlusSign,
    hideLabel: hideLetterLabel,
    hideSymbol,
    hideDec,
    hide00Dec,
  } = opts;

  let result = "";

  const isPositive = checkIsPositiveNumber(amount);
  if (!hidePlusSign || !isPositive) {
    result += isPositive ? "+" : "-";
  }

  if (!hideLetterLabel) {
    result += `${currencyObj.letterLabel} `;
  }

  if (!hideSymbol) {
    result += `${currencyObj.symbol}`;
  }

  const amountStr = formatCommaMoney(Math.abs(amount), currencyObj.hasDecimal);
  if (hideDec) {
    result += amountStr.replace(/\..*$/, "");
  } else if (hide00Dec) {
    result += amountStr.replace(/\.00$/, "");
  } else {
    result += amountStr;
  }

  return result;
};
