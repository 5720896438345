/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "@/utils/webapi";
import { type EntityInfo } from "../../http_pms/common/common_type";
import { type PartitionKind, type Image } from "./common_type";

export namespace CommonService {
  // 根据实体类型和id获取图片
  export function ListImageByEntity(req: ListImageByEntityReq, config?: any) {
    return webapi.post<ListImageByEntityResp>(
      "/api/v1/CommonService_ListImageByEntity",
      req,
      config,
    );
  }
}
export interface ListImageByEntityReq {
  // 某个实体下的图片 entity 和 对应id
  entityInfo: EntityInfo;
  // 分区
  partition?: PartitionKind;
}

export interface ListImageByEntityResp {
  images: Image[];
}
