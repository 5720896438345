import { Helmet } from "react-helmet";
import { useInfiniteScroll } from "ahooks";
import { useRef } from "react";
import type * as order_type from "@api/http_resv/order/order_type";
import { OrderService } from "@api/http_resv/order/order_srv";
import dayjs from "dayjs";
import clsx from "clsx";
import Empty from "@/assets/images/empty.svg?react";
import { useCustomNavigate } from "@/hooks";
import { RoomCard, RoomCardHeaderWrapper } from "../components/RoomCard";
import { genPageTitle } from "@/utils";
import { PATHS } from "@/constants/path";
import { toMMDD } from "@/lib/utils";
import { makeStorageUrl } from "@/utils/file";
import {
  getCurrentTimeZoneDate,
  getOrderStatus,
  OrderStatusEnum,
} from "@/utils/order";
import { ErrorComp, FixedHeaderWrapper, Loading } from "@/components";
import { useIsPC } from "@/stores";

const OrderStatusBadge: React.FC<{ orderInList: order_type.OrderInList }> = ({
  orderInList,
}) => {
  const status = getOrderStatus({
    dateStart: orderInList.order.startDate,
    dateEnd: orderInList.order.endDate,
    paidAt: orderInList.order.paidAt,
    isCancelled: orderInList.order.isCancelled,
  });

  if (status === OrderStatusEnum.Unpaid) {
    return <div className="text-danger text-base font-medium">待付款</div>;
  }

  if (status === OrderStatusEnum.Cancelled) {
    return <div className="text-base font-medium text-[#979797]">交易关闭</div>;
  }

  if (status === OrderStatusEnum.WaitingCheckIn) {
    return (
      <div className="text-text-1 text-base font-medium">
        <span className="text-easbnb-brand">
          {Math.max(
            dayjs(orderInList.order.startDate).diff(
              getCurrentTimeZoneDate(),
              "day",
            ),
            1,
          )}
          天
        </span>
        <span className="ml-1 text-sm">后入住</span>
      </div>
    );
  }

  if (status === OrderStatusEnum.CheckInToday) {
    return (
      <div className="text-easbnb-brand text-base font-medium">今日入住</div>
    );
  }

  if (status === OrderStatusEnum.CheckIn) {
    return <div className="text-base font-medium text-[#F74D36]">入住中</div>;
  }

  if (status === OrderStatusEnum.CheckOutToday) {
    return <div className="text-base font-medium text-[#F9B908]">今日退房</div>;
  }

  if (status === OrderStatusEnum.CheckedOut) {
    return <div className="text-base font-medium text-[#979797]">已退房</div>;
  }

  return null;
};

export const OrderList = () => {
  const customNavigate = useCustomNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const isPC = useIsPC();

  const onGoToOrderDetail = (orderId: string) => {
    customNavigate(PATHS.ORDER, { id: orderId });
  };

  const {
    data: listData,
    loading,
    noMore,
    error,
  } = useInfiniteScroll(
    async () => {
      const resp = await OrderService.List({
        current: 1,
        pageSize: 100,
      });

      const result = {
        list: resp.orders,
        total: resp.total,
      };
      return result;
    },
    {
      target: ref,
      isNoMore: (
        data: { list: order_type.OrderInList[]; total: number } | undefined,
      ) => {
        // console.log(data?.list.length, data?.total);

        return data?.list?.length! >= data?.total!;
      },
    },
  );

  if (listData?.list?.length === 0) {
    return (
      <div className="flex h-[50vh] w-full items-center justify-center">
        <Helmet>
          <title>{genPageTitle("订单列表")}</title>
        </Helmet>

        <div className="flex flex-col items-center pt-40">
          <Empty />
          <div className="text-text-4 mt-3 text-sm">暂无订单</div>
        </div>
      </div>
    );
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorComp error={error} />;
  }

  return (
    <div ref={ref}>
      <Helmet>
        <title>{genPageTitle("订单列表")}</title>
      </Helmet>

      {isPC && <FixedHeaderWrapper onlyTitle={true} />}

      {/* 列表 */}
      <div
        className={clsx(
          isPC ? "space-y-4 py-5" : "space-y-3 px-4 py-3",
          "bg-page-bg mx-auto overflow-y-auto ",
        )}
      >
        {listData?.list.map((orderInList) => (
          <RoomCard
            key={orderInList.order.id}
            imageUri={makeStorageUrl(orderInList.roomImageUri)}
            room={orderInList.room}
            quotedPrice={orderInList.order.price}
            disableLink={true}
            header={
              <RoomCardHeaderWrapper>
                <div className="text-text-666 text-sm">
                  {toMMDD(orderInList.order.startDate, { splitStr: "/" })}-
                  {toMMDD(orderInList.order.endDate, { splitStr: "/" })} 共
                  {orderInList.order.stays}晚
                </div>

                <OrderStatusBadge orderInList={orderInList} />
              </RoomCardHeaderWrapper>
            }
            extra={
              getOrderStatus({
                dateStart: orderInList.order.startDate,
                dateEnd: orderInList.order.endDate,
                paidAt: orderInList.order.paidAt,
                isCancelled: orderInList.order.isCancelled,
              }) === OrderStatusEnum.Unpaid && (
                <div className={clsx(isPC ? "" : "pt-4", "flex justify-end")}>
                  <button
                    type="button"
                    className="hover:bg-text-333 rounded-full bg-[#3D3D3D] px-7 py-2 text-sm font-medium text-white"
                  >
                    去支付
                  </button>
                </div>
              )
            }
            onClick={() => onGoToOrderDetail(orderInList.order.id)}
          />
        ))}
        {noMore && (
          <div className="text-text-3 flex justify-center py-6 text-sm">
            已经到底啦～
          </div>
        )}
      </div>
    </div>
  );
};
