import { type OrderInDetail } from "@api/http_resv/order/order_type";
import clsx from "clsx";
import { Card } from "@/components";
import { useIsPC } from "@/stores";

interface CheckOutPolicyCardProps {
  data: OrderInDetail;
}

export const CheckOutPolicyCard: React.FC<CheckOutPolicyCardProps> = ({
  data,
}) => {
  const isPC = useIsPC();

  if (data?.hidePolicyCard) {
    return null;
  }

  return (
    <Card
      className={clsx({
        "p-6": isPC,
      })}
      title="退订政策"
    >
      <div className="text-text-666 whitespace-pre-line pt-3 text-sm">
        {data?.policy}
      </div>
    </Card>
  );
};
