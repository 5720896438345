import { useEventListener } from "ahooks";
import { createGlobalStore } from "hox";
import { useState } from "react";

const MOBILE_WIDTH = 768;

export const [useIsPC, getIsPC] = createGlobalStore(() => {
  useEventListener("resize", () => {
    setIsPC(window.innerWidth > MOBILE_WIDTH);
  });

  const [isPC, setIsPC] = useState(() => {
    return window.innerWidth > MOBILE_WIDTH;
  });
  return isPC;
});
