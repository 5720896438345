import { type RoomInDetail } from "@api/http_resv/room/room_type";
import clsx from "clsx";
import { Card } from "@/components";
import { useIsPC } from "@/stores";
import { getRoomInfo } from "@/utils/roomInfo";

interface TitleCardProps {
  data: RoomInDetail;
}

export const TitleCard: React.FC<TitleCardProps> = ({ data }) => {
  const isPC = useIsPC();
  const roomInfoText = getRoomInfo({
    area: data?.room.roomSize,
    bed: data?.room.singleBedCount,
    doubleBed: data?.room.doubleBedCount,
  });

  return (
    <>
      {isPC ? (
        <Card className="mb-3 px-6 py-4">
          <TitleInfo data={data} roomInfoText={roomInfoText} />
        </Card>
      ) : (
        <div>
          <TitleInfo data={data} roomInfoText={roomInfoText} />
        </div>
      )}
    </>
  );
};

const TitleInfo: React.FC<{ data: RoomInDetail; roomInfoText: string }> = ({
  data,
  roomInfoText,
}) => {
  const isPC = useIsPC();

  return (
    <div className={clsx({ "mb-2": !isPC })}>
      <div className={clsx("text-text-1 text-xl font-medium")}>
        {data?.room.title}
      </div>

      {(data.room.roomSize > 0 || data.room.personCapacity > 0) && (
        <div className="mb-3 mt-2 flex items-center space-x-2">
          {data.room.roomSize > 0 && (
            <span className="text-text-666 text-sm">{roomInfoText}</span>
          )}
          {data.room.personCapacity > 0 && (
            <span className="text-easbnb-brand text-sm">
              可住{data?.room.personCapacity}人
            </span>
          )}
        </div>
      )}
    </div>
  );
};
