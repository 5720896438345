import { Helmet } from "react-helmet";
import { useRequest } from "ahooks";
import type * as common_type from "@api/http_resv/common/common_type";
import { EntityKind } from "@api/http_pms/const/const_enum";
import { NavBar, Toast, Image, ImagePreview } from "react-vant";
import clsx from "clsx";
import { CommonService } from "@api/http_resv/common/common_srv";
import { OrderService } from "@api/http_resv/order/order_srv";
import { useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import { isEmpty, isUndefined } from "lodash-es";
import { makeStorageUrl } from "@/utils/file";
import {
  FixedBottomWrapper,
  Card,
  Loading,
  ErrorComp,
  FixedHeaderWrapper,
} from "@/components";
import { PATHS } from "@/constants/path";
import { useCustomNavigate } from "@/hooks";

import { genPageTitle } from "@/utils";
import { useIsPC } from "@/stores";

export const OrderAgreement = () => {
  const customNavigate = useCustomNavigate();
  const location = useLocation();
  const params = useParams<{ id?: string }>();
  const id = params.id;
  const { state } = location;

  const [isSigned, setIsSigned] = useState(() => {
    return state?.isSigned;
  });
  const isPC = useIsPC();

  const { data, error, loading } = useRequest(
    async () => {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        overlay: true,
      });
      const resp = await CommonService.ListImageByEntity({
        entityInfo: {
          entityKind: EntityKind.ResvContract,
          relationId: id!,
          relationSubId: "",
        },
      });

      const result = {
        images: resp.images
          .filter((item) => item.partition === 2)
          .sort((a, b) => a.rank - b.rank),
        downloadUrl: resp.images.filter((item) => item.partition === 1)[0]?.uri,
      };

      return result;
    },
    {
      onFinally() {
        Toast.clear();
      },
    },
  );

  const { loading: orderLoading, error: orderError } = useRequest(
    async () => {
      const resp = await OrderService.Get({
        id: id!,
      });

      if (
        isEmpty(resp.order.order.signedAt) ||
        isUndefined(resp.order.order.signedAt)
      ) {
        setIsSigned(false);
      } else {
        setIsSigned(true);
      }

      return resp;
    },
    {
      ready: isUndefined(isSigned),
    },
  );

  const downLoadAgreement = () => {
    if (!data?.downloadUrl) {
      return;
    }
    window.open(`${makeStorageUrl(data.downloadUrl)}?dl=1`);
  };

  const onSignAgreement = async () => {
    const resp = await OrderService.SignContract({
      orderId: id!,
    });

    if (resp) {
      customNavigate(PATHS.PAY, {
        id,
      });
    } else {
      Toast.fail("签署失败,请重试");
    }
  };

  const onCancelAgreement = () => {
    customNavigate(PATHS.ORDER, {
      id,
    });
  };

  if (loading || orderLoading) {
    return <Loading />;
  }

  if (error || orderError) {
    return <ErrorComp error={error || orderError} />;
  }

  return (
    <div className="bg-page-bg">
      {isPC ? (
        <FixedHeaderWrapper pageName="协议签署" goBack={onCancelAgreement} />
      ) : (
        <NavBar
          title="协议签署"
          leftText="返回"
          placeholder={true}
          zIndex={50}
          onClickLeft={onCancelAgreement}
        />
      )}
      <Helmet>
        <title>{genPageTitle("协议签署")}</title>
      </Helmet>

      <div
        className={clsx(
          isPC ? "mx-auto w-[720px] lg:w-[1024px]" : "mx-4",
          "bg-page-bg space-y-3 py-4",
        )}
      >
        {!isSigned && (
          <Card className="flex justify-between">
            <div>
              <div className="mb-3 flex items-center space-x-6">
                <span className="text-text-1 text-base font-medium">
                  请您先阅读协议内容无误后确认并签字
                </span>
              </div>
              <div className="text-text-666 text-sm">
                若有任何疑问请随时与我们联系
              </div>
            </div>
            {isPC && (
              <ButtonGroup
                isPC={isPC}
                onCancelAgreement={onCancelAgreement}
                onSignAgreement={onSignAgreement}
              />
            )}
          </Card>
        )}
        {data?.images?.map((item: common_type.Image, index) => (
          <Card key={item.rank}>
            <Image
              src={makeStorageUrl(item.uri)}
              fit="cover"
              lazyload={true}
              onClick={() => {
                ImagePreview.open({
                  images: data.images.map((image) => makeStorageUrl(image.uri)),
                  startPosition: index,
                });
              }}
            />
          </Card>
        ))}
      </div>
      {!isSigned && !isPC && (
        <FixedBottomWrapper className="pb-0">
          <ButtonGroup
            isPC={isPC}
            onCancelAgreement={onCancelAgreement}
            onSignAgreement={onSignAgreement}
          />
        </FixedBottomWrapper>
      )}

      <button
        type="button"
        className={clsx(
          isPC ? "bottom-[82px] lg:bottom-[38px]" : "bottom-28",
          "fixed left-1/2 z-10 h-12 w-40 -translate-x-1/2 rounded-full border px-10 py-3 text-base font-medium shadow-lg",
          isSigned
            ? "bg-resv-black text-white"
            : "text-text-1 border-[#B8BCCB] bg-white",
        )}
        onClick={downLoadAgreement}
      >
        下载协议
      </button>
    </div>
  );
};

const ButtonGroup: React.FC<{
  isPC: boolean;
  onCancelAgreement: () => void;
  onSignAgreement: () => void;
}> = ({ isPC, onCancelAgreement, onSignAgreement }) => {
  return (
    <div
      className={clsx(
        isPC ? "mr-3 gap-6" : "w-full  justify-between bg-white px-10 py-3",
        "flex  items-center",
      )}
    >
      <button
        onClick={onCancelAgreement}
        type="button"
        className="text-text-1 h-12 rounded-full border border-[#B8BCCB] bg-white px-10 py-3 text-base font-medium hover:bg-[#F5F7F9]"
      >
        取消
      </button>
      <button
        type="button"
        onClick={onSignAgreement}
        className="hover:bg-text-333 h-12 rounded-full bg-[#3D3D3D] px-10 py-3 text-base font-medium text-white"
      >
        确认并同意
      </button>
    </div>
  );
};
