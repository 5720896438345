import type * as common_type from "@api/http_pms/common/common_type";
import { Card } from "@/components";
import { getMoneyText } from "@/utils/money";

interface PriceCardCardProps {
  quotedPrice?: common_type.CurrencyAmount;
  basePrice?: common_type.CurrencyAmount;
  lowestPrice?: common_type.CurrencyAmount;
  canBook?: boolean;
  nights?: number;
}

export const PriceCard: React.FC<PriceCardCardProps> = ({
  quotedPrice,
  basePrice,
  lowestPrice,
  canBook,
  nights,
}) => {
  return (
    <Card className="p-0">
      <div className="bg-page-bg mt-6 flex flex-col items-center justify-between rounded-xl p-4">
        {quotedPrice && canBook && (
          <div className="flex w-full items-center justify-between border-b border-[#D2DDF8] pb-2">
            <span className="text-text-666 text-sm">订单金额</span>
            <span className="text-danger text-2xl font-medium">
              {getMoneyText(quotedPrice.amount, quotedPrice.currency, {
                hidePlusSign: true,
              })}
            </span>
          </div>
        )}
        {basePrice && quotedPrice && canBook && (
          <div className="text-text-666 mt-2 flex w-full flex-col space-y-3 text-sm">
            {quotedPrice.amount !== basePrice.amount && (
              <div className="flex justify-between">
                <span>原价</span>
                <span className="line-through">
                  {getMoneyText(basePrice.amount, basePrice.currency, {
                    hideLabel: true,
                    hidePlusSign: true,
                  })}
                  /晚
                </span>
              </div>
            )}
            {nights && (
              <div className="flex justify-between">
                <span>均价</span>
                <span>
                  {getMoneyText(
                    quotedPrice.amount / nights,
                    quotedPrice.currency,
                    {
                      hideLabel: true,
                      hidePlusSign: true,
                    },
                  )}
                  /晚
                </span>
              </div>
            )}
          </div>
        )}
        {lowestPrice && (
          <div className="flex w-full items-center justify-between">
            <span className="text-text-666 text-sm">房间单价</span>
            <span className=" flex items-center">
              <span className="text-danger mr-2 text-2xl font-medium">
                {getMoneyText(lowestPrice.amount, lowestPrice.currency, {
                  hidePlusSign: true,
                })}
              </span>
              <span className="text-text-666 text-sm">起/晚</span>
            </span>
          </div>
        )}
      </div>
    </Card>
  );
};
