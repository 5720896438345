export const AREA_NUMBER: Record<string, string>[] = [
  { id: "CN", code: "86", name: "China", name_cn: "中国", name_jp: "中国" },
  { id: "JP", code: "81", name: "Japan", name_cn: "日本", name_jp: "日本" },
  {
    id: "KR",
    code: "82",
    name: "South Korea",
    name_cn: "韩国",
    name_jp: "韓国",
  },
  {
    id: "US",
    code: "1",
    name: "United States",
    name_cn: "美国",
    name_jp: "アメリカ合衆国",
  },
  {
    id: "HK",
    code: "852",
    name: "Hong Kong",
    name_cn: "中国香港",
    name_jp: "香港",
  },
  {
    id: "MO",
    code: "853",
    name: "Macau",
    name_cn: "中国澳门",
    name_jp: "マカオ",
  },
  {
    id: "TW",
    code: "886",
    name: "Taiwan",
    name_cn: "台湾地区",
    name_jp: "台湾",
  },
  { id: "DE", code: "49", name: "Germany", name_cn: "德国", name_jp: "ドイツ" },
  {
    id: "GB",
    code: "44",
    name: "United Kingdom",
    name_cn: "英国",
    name_jp: "イギリス",
  },
  {
    id: "FR",
    code: "33",
    name: "France",
    name_cn: "法国",
    name_jp: "フランス",
  },
  {
    id: "IT",
    code: "39",
    name: "Italy",
    name_cn: "意大利",
    name_jp: "イタリア",
  },
  {
    id: "MY",
    code: "60",
    name: "Malaysia",
    name_cn: "马来西亚",
    name_jp: "マレーシア",
  },
  {
    id: "BR",
    code: "55",
    name: "Brazil",
    name_cn: "巴西",
    name_jp: "ブラジル",
  },
  {
    id: "RU",
    code: "7",
    name: "Russia",
    name_cn: "俄罗斯",
    name_jp: "ロシア連邦",
  },
  { id: "CA", code: "1", name: "Canada", name_cn: "加拿大", name_jp: "カナダ" },
  {
    id: "AU",
    code: "61",
    name: "Australia",
    name_cn: "澳大利亚",
    name_jp: "オーストラリア",
  },
  {
    id: "ES",
    code: "34",
    name: "Spain",
    name_cn: "西班牙",
    name_jp: "スペイン",
  },
  {
    id: "MX",
    code: "52",
    name: "Mexico",
    name_cn: "墨西哥",
    name_jp: "メキシコ",
  },
  {
    id: "SE",
    code: "46",
    name: "Sweden",
    name_cn: "瑞典",
    name_jp: "スウェーデン",
  },
  {
    id: "CH",
    code: "41",
    name: "Switzerland",
    name_cn: "瑞士",
    name_jp: "スイス",
  },
  {
    id: "SG",
    code: "65",
    name: "Singapore",
    name_cn: "新加坡",
    name_jp: "シンガポール",
  },
  {
    id: "ID",
    code: "62",
    name: "Indonesia",
    name_cn: "印度尼西亚",
    name_jp: "インドネシア",
  },
  {
    id: "TR",
    code: "90",
    name: "Turkey",
    name_cn: "土耳其",
    name_jp: "トルコ",
  },
  {
    id: "SA",
    code: "966",
    name: "Saudi Arabia",
    name_cn: "沙特阿拉伯",
    name_jp: "サウジアラビア",
  },
  {
    id: "NG",
    code: "234",
    name: "Nigeria",
    name_cn: "尼日利亚",
    name_jp: "ナイジェリア",
  },
  {
    id: "AR",
    code: "54",
    name: "Argentina",
    name_cn: "阿根廷",
    name_jp: "アルゼンチン",
  },
  {
    id: "BE",
    code: "32",
    name: "Belgium",
    name_cn: "比利时",
    name_jp: "ベルギー",
  },
  { id: "IN", code: "91", name: "India", name_cn: "印度", name_jp: "インド" },
  {
    id: "CG",
    code: "242",
    name: "Republic of the Congo",
    name_cn: "刚果",
    name_jp: "コンゴ共和国",
  },
  {
    id: "CD",
    code: "243",
    name: "DR Congo",
    name_cn: "刚果民主共和国",
    name_jp: "コンゴ民主共和国",
  },
  { id: "XK", code: "383", name: "Kosovo", name_cn: "科索沃", name_jp: "" },
  {
    id: "MK",
    code: "389",
    name: "North Macedonia",
    name_cn: "北马其顿",
    name_jp: "北マケドニア ",
  },
  {
    id: "AL",
    code: "355",
    name: "Albania",
    name_cn: "阿尔巴尼亚",
    name_jp: "アルバニア",
  },
  {
    id: "DZ",
    code: "213",
    name: "Algeria",
    name_cn: "阿尔及利亚",
    name_jp: "アルジェリア",
  },
  {
    id: "AF",
    code: "93",
    name: "Afghanistan",
    name_cn: "阿富汗",
    name_jp: "アフガニスタン",
  },
  {
    id: "AE",
    code: "971",
    name: "United Arab Emirates",
    name_cn: "阿拉伯联合酋长国",
    name_jp: "アラブ首長国連邦",
  },
  {
    id: "AW",
    code: "297",
    name: "Aruba",
    name_cn: "阿鲁巴",
    name_jp: "アルバ",
  },
  { id: "OM", code: "968", name: "Oman", name_cn: "阿曼", name_jp: "オマーン" },
  {
    id: "AZ",
    code: "994",
    name: "Azerbaijan",
    name_cn: "阿塞拜疆",
    name_jp: "アゼルバイジャン",
  },
  { id: "EG", code: "20", name: "Egypt", name_cn: "埃及", name_jp: "エジプト" },
  {
    id: "ET",
    code: "251",
    name: "Ethiopia",
    name_cn: "埃塞俄比亚",
    name_jp: "エチオピア",
  },
  {
    id: "IE",
    code: "353",
    name: "Ireland",
    name_cn: "爱尔兰",
    name_jp: "アイルランド",
  },
  {
    id: "EE",
    code: "372",
    name: "Estonia",
    name_cn: "爱沙尼亚",
    name_jp: "エストニア",
  },
  {
    id: "AD",
    code: "376",
    name: "Andorra",
    name_cn: "安道尔",
    name_jp: "アンドラ",
  },
  {
    id: "AO",
    code: "244",
    name: "Angola",
    name_cn: "安哥拉",
    name_jp: "アンゴラ",
  },
  {
    id: "AI",
    code: "1",
    name: "Anguilla",
    name_cn: "安圭拉",
    name_jp: "アンギラ",
  },
  {
    id: "AG",
    code: "1",
    name: "Antigua and Barbuda",
    name_cn: "安提瓜和巴布达",
    name_jp: "アンティグア・バーブーダ",
  },
  {
    id: "AT",
    code: "43",
    name: "Austria",
    name_cn: "奥地利",
    name_jp: "オーストリア",
  },
  {
    id: "AX",
    code: "358",
    name: "Åland Islands",
    name_cn: "奥兰群岛",
    name_jp: "オーランド諸島",
  },
  {
    id: "BB",
    code: "1",
    name: "Barbados",
    name_cn: "巴巴多斯",
    name_jp: "バルバドス",
  },
  {
    id: "PG",
    code: "675",
    name: "Papua New Guinea",
    name_cn: "巴布亚新几内亚",
    name_jp: "パプアニューギニア",
  },
  {
    id: "BS",
    code: "1",
    name: "Bahamas",
    name_cn: "巴哈马",
    name_jp: "バハマ",
  },
  {
    id: "PK",
    code: "92",
    name: "Pakistan",
    name_cn: "巴基斯坦",
    name_jp: "パキスタン",
  },
  {
    id: "PY",
    code: "595",
    name: "Paraguay",
    name_cn: "巴拉圭",
    name_jp: "パラグアイ",
  },
  {
    id: "PS",
    code: "970",
    name: "Palestine",
    name_cn: "巴勒斯坦领土",
    name_jp: "パレスチナ",
  },
  {
    id: "BH",
    code: "973",
    name: "Bahrain",
    name_cn: "巴林",
    name_jp: "バーレーン",
  },
  {
    id: "PA",
    code: "507",
    name: "Panama",
    name_cn: "巴拿马",
    name_jp: "パナマ",
  },
  {
    id: "BY",
    code: "375",
    name: "Belarus",
    name_cn: "白俄罗斯",
    name_jp: "ベラルーシ",
  },
  {
    id: "BM",
    code: "1",
    name: "Bermuda",
    name_cn: "百慕大",
    name_jp: "バミューダ",
  },
  {
    id: "BG",
    code: "359",
    name: "Bulgaria",
    name_cn: "保加利亚",
    name_jp: "ブルガリア",
  },
  {
    id: "MP",
    code: "1",
    name: "Northern Mariana Islands",
    name_cn: "北马里亚纳群岛",
    name_jp: "北マリアナ諸島",
  },
  { id: "BJ", code: "229", name: "Benin", name_cn: "贝宁", name_jp: "ベナン" },
  {
    id: "IS",
    code: "354",
    name: "Iceland",
    name_cn: "冰岛",
    name_jp: "アイスランド",
  },
  {
    id: "PR",
    code: "1",
    name: "Puerto Rico",
    name_cn: "波多黎各",
    name_jp: "プエルトリコ",
  },
  {
    id: "PL",
    code: "48",
    name: "Poland",
    name_cn: "波兰",
    name_jp: "ポーランド",
  },
  {
    id: "BA",
    code: "387",
    name: "Bosnia and Herzegovina",
    name_cn: "波斯尼亚和黑塞哥维那",
    name_jp: "ボスニア・ヘルツェゴビナ",
  },
  {
    id: "BO",
    code: "591",
    name: "Bolivia",
    name_cn: "玻利维亚",
    name_jp: "ボリビア多民族国",
  },
  {
    id: "BZ",
    code: "501",
    name: "Belize",
    name_cn: "伯利兹",
    name_jp: "ベリーズ",
  },
  {
    id: "BW",
    code: "267",
    name: "Botswana",
    name_cn: "博茨瓦纳",
    name_jp: "ボツワナ",
  },
  {
    id: "BT",
    code: "975",
    name: "Bhutan",
    name_cn: "不丹",
    name_jp: "ブータン",
  },
  {
    id: "BF",
    code: "226",
    name: "Burkina Faso",
    name_cn: "布基纳法索",
    name_jp: "ブルキナファソ",
  },
  {
    id: "BI",
    code: "257",
    name: "Burundi",
    name_cn: "布隆迪",
    name_jp: "ブルンジ",
  },
  {
    id: "GQ",
    code: "240",
    name: "Equatorial Guinea",
    name_cn: "赤道几内亚",
    name_jp: "赤道ギニア",
  },
  {
    id: "DK",
    code: "45",
    name: "Denmark",
    name_cn: "丹麦",
    name_jp: "デンマーク",
  },
  {
    id: "TL",
    code: "670",
    name: "Timor-Leste",
    name_cn: "东帝汶",
    name_jp: "東ティモール",
  },
  { id: "TG", code: "228", name: "Togo", name_cn: "多哥", name_jp: "トーゴ" },
  {
    id: "DO",
    code: "1",
    name: "Dominican Republic",
    name_cn: "多米尼加共和国",
    name_jp: "ドミニカ共和国",
  },
  {
    id: "DM",
    code: "1",
    name: "Dominica",
    name_cn: "多米尼克",
    name_jp: "ドミニカ国",
  },
  {
    id: "EC",
    code: "593",
    name: "Ecuador",
    name_cn: "厄瓜多尔",
    name_jp: "エクアドル",
  },
  {
    id: "ER",
    code: "291",
    name: "Eritrea",
    name_cn: "厄立特里亚",
    name_jp: "エリトリア",
  },
  {
    id: "FO",
    code: "298",
    name: "Faroe Islands",
    name_cn: "法罗群岛",
    name_jp: "フェロー諸島",
  },
  {
    id: "PF",
    code: "689",
    name: "French Polynesia",
    name_cn: "法属波利尼西亚",
    name_jp: "フランス領ポリネシア",
  },
  {
    id: "GF",
    code: "594",
    name: "French Guiana",
    name_cn: "法属圭亚那",
    name_jp: "フランス領ギアナ",
  },
  {
    id: "MF",
    code: "590",
    name: "Saint Martin",
    name_cn: "法属圣马丁",
    name_jp: "サン・マルタン（フランス領）",
  },
  {
    id: "VA",
    code: "379",
    name: "Vatican City",
    name_cn: "梵蒂冈",
    name_jp: "バチカン市国",
  },
  {
    id: "PH",
    code: "63",
    name: "Philippines",
    name_cn: "菲律宾",
    name_jp: "フィリピン",
  },
  { id: "FJ", code: "679", name: "Fiji", name_cn: "斐济", name_jp: "フィジー" },
  {
    id: "FI",
    code: "358",
    name: "Finland",
    name_cn: "芬兰",
    name_jp: "フィンランド",
  },
  {
    id: "CV",
    code: "238",
    name: "Cape Verde",
    name_cn: "佛得角",
    name_jp: "カーボベルデ",
  },
  {
    id: "FK",
    code: "500",
    name: "Falkland Islands",
    name_cn: "福克兰群岛",
    name_jp: "フォークランド（マルビナス）諸島",
  },
  {
    id: "GM",
    code: "220",
    name: "Gambia",
    name_cn: "冈比亚",
    name_jp: "ガンビア",
  },
  {
    id: "CO",
    code: "57",
    name: "Colombia",
    name_cn: "哥伦比亚",
    name_jp: "コロンビア",
  },
  {
    id: "CR",
    code: "506",
    name: "Costa Rica",
    name_cn: "哥斯达黎加",
    name_jp: "コスタリカ",
  },
  {
    id: "GD",
    code: "1",
    name: "Grenada",
    name_cn: "格林纳达",
    name_jp: "グレナダ",
  },
  {
    id: "GL",
    code: "299",
    name: "Greenland",
    name_cn: "格陵兰",
    name_jp: "グリーンランド",
  },
  {
    id: "GE",
    code: "995",
    name: "Georgia",
    name_cn: "格鲁吉亚",
    name_jp: "グルジア",
  },
  {
    id: "GG",
    code: "44",
    name: "Guernsey",
    name_cn: "根西岛",
    name_jp: "ガーンジー",
  },
  { id: "CU", code: "53", name: "Cuba", name_cn: "古巴", name_jp: "キューバ" },
  {
    id: "GP",
    code: "590",
    name: "Guadeloupe",
    name_cn: "瓜德罗普",
    name_jp: "グアドループ",
  },
  { id: "GU", code: "1", name: "Guam", name_cn: "关岛", name_jp: "グアム" },
  {
    id: "GY",
    code: "592",
    name: "Guyana",
    name_cn: "圭亚那",
    name_jp: "ガイアナ",
  },
  {
    id: "KZ",
    code: "7",
    name: "Kazakhstan",
    name_cn: "哈萨克斯坦",
    name_jp: "カザフスタン",
  },
  { id: "HT", code: "509", name: "Haiti", name_cn: "海地", name_jp: "ハイチ" },
  {
    id: "NL",
    code: "31",
    name: "Netherlands",
    name_cn: "荷兰",
    name_jp: "オランダ",
  },
  {
    id: "BQ",
    code: "599",
    name: "Caribbean Netherlands",
    name_cn: "荷属加勒比区",
    name_jp: "ボネール、シント・ユースタティウスおよびサバ",
  },
  {
    id: "SX",
    code: "1",
    name: "Sint Maarten",
    name_cn: "荷属圣马丁",
    name_jp: "シント・マールテン",
  },
  {
    id: "ME",
    code: "382",
    name: "Montenegro",
    name_cn: "黑山",
    name_jp: "モンテネグロ",
  },
  {
    id: "HN",
    code: "504",
    name: "Honduras",
    name_cn: "洪都拉斯",
    name_jp: "ホンジュラス",
  },
  {
    id: "KI",
    code: "686",
    name: "Kiribati",
    name_cn: "基里巴斯",
    name_jp: "キリバス",
  },
  {
    id: "DJ",
    code: "253",
    name: "Djibouti",
    name_cn: "吉布提",
    name_jp: "ジブチ",
  },
  {
    id: "KG",
    code: "996",
    name: "Kyrgyzstan",
    name_cn: "吉尔吉斯斯坦",
    name_jp: "キルギス",
  },
  {
    id: "GN",
    code: "224",
    name: "Guinea",
    name_cn: "几内亚",
    name_jp: "ギニア",
  },
  {
    id: "GW",
    code: "245",
    name: "Guinea-Bissau",
    name_cn: "几内亚比绍",
    name_jp: "ギニアビサウ",
  },
  { id: "GH", code: "233", name: "Ghana", name_cn: "加纳", name_jp: "ガーナ" },
  { id: "GA", code: "241", name: "Gabon", name_cn: "加蓬", name_jp: "ガボン" },
  {
    id: "KH",
    code: "855",
    name: "Cambodia",
    name_cn: "柬埔寨",
    name_jp: "カンボジア",
  },
  {
    id: "CZ",
    code: "420",
    name: "Czechia",
    name_cn: "捷克",
    name_jp: "チェコ",
  },
  {
    id: "ZW",
    code: "263",
    name: "Zimbabwe",
    name_cn: "津巴布韦",
    name_jp: "ジンバブエ",
  },
  {
    id: "CM",
    code: "237",
    name: "Cameroon",
    name_cn: "喀麦隆",
    name_jp: "カメルーン",
  },
  {
    id: "QA",
    code: "974",
    name: "Qatar",
    name_cn: "卡塔尔",
    name_jp: "カタール",
  },
  {
    id: "KY",
    code: "1",
    name: "Cayman Islands",
    name_cn: "开曼群岛",
    name_jp: "ケイマン諸島",
  },
  {
    id: "CC",
    code: "61",
    name: "Cocos (Keeling) Islands",
    name_cn: "科科斯（基林）群岛",
    name_jp: "ココス（キーリング）諸島",
  },
  {
    id: "KM",
    code: "269",
    name: "Comoros",
    name_cn: "科摩罗",
    name_jp: "コモロ",
  },
  {
    id: "CI",
    code: "225",
    name: "Ivory Coast",
    name_cn: "科特迪瓦",
    name_jp: "コートジボワール",
  },
  {
    id: "KW",
    code: "965",
    name: "Kuwait",
    name_cn: "科威特",
    name_jp: "クウェート",
  },
  {
    id: "HR",
    code: "385",
    name: "Croatia",
    name_cn: "克罗地亚",
    name_jp: "クロアチア",
  },
  {
    id: "KE",
    code: "254",
    name: "Kenya",
    name_cn: "肯尼亚",
    name_jp: "ケニア",
  },
  {
    id: "CK",
    code: "682",
    name: "Cook Islands",
    name_cn: "库克群岛",
    name_jp: "クック諸島",
  },
  { id: "CW", code: "599", name: "Curaçao", name_cn: "库拉索", name_jp: "" },
  {
    id: "LV",
    code: "371",
    name: "Latvia",
    name_cn: "拉脱维亚",
    name_jp: "ラトビア",
  },
  {
    id: "LS",
    code: "266",
    name: "Lesotho",
    name_cn: "莱索托",
    name_jp: "レソト",
  },
  {
    id: "LA",
    code: "856",
    name: "Laos",
    name_cn: "老挝",
    name_jp: "ラオス人民民主共和国",
  },
  {
    id: "LB",
    code: "961",
    name: "Lebanon",
    name_cn: "黎巴嫩",
    name_jp: "レバノン",
  },
  {
    id: "LT",
    code: "370",
    name: "Lithuania",
    name_cn: "立陶宛",
    name_jp: "リトアニア",
  },
  {
    id: "LR",
    code: "231",
    name: "Liberia",
    name_cn: "利比里亚",
    name_jp: "リベリア",
  },
  {
    id: "LY",
    code: "218",
    name: "Libya",
    name_cn: "利比亚",
    name_jp: "リビア",
  },
  {
    id: "LI",
    code: "423",
    name: "Liechtenstein",
    name_cn: "列支敦士登",
    name_jp: "リヒテンシュタイン",
  },
  {
    id: "RE",
    code: "262",
    name: "Réunion",
    name_cn: "留尼汪",
    name_jp: "レユニオン",
  },
  {
    id: "LU",
    code: "352",
    name: "Luxembourg",
    name_cn: "卢森堡",
    name_jp: "ルクセンブルク",
  },
  {
    id: "RW",
    code: "250",
    name: "Rwanda",
    name_cn: "卢旺达",
    name_jp: "ルワンダ",
  },
  {
    id: "RO",
    code: "40",
    name: "Romania",
    name_cn: "罗马尼亚",
    name_jp: "ルーマニア",
  },
  {
    id: "MG",
    code: "261",
    name: "Madagascar",
    name_cn: "马达加斯加",
    name_jp: "マダガスカル",
  },
  {
    id: "IM",
    code: "44",
    name: "Isle of Man",
    name_cn: "马恩岛",
    name_jp: "マン島",
  },
  {
    id: "MV",
    code: "960",
    name: "Maldives",
    name_cn: "马尔代夫",
    name_jp: "モルディブ",
  },
  {
    id: "MT",
    code: "356",
    name: "Malta",
    name_cn: "马耳他",
    name_jp: "マルタ",
  },
  {
    id: "MW",
    code: "265",
    name: "Malawi",
    name_cn: "马拉维",
    name_jp: "マラウイ",
  },
  { id: "ML", code: "223", name: "Mali", name_cn: "马里", name_jp: "マリ" },
  {
    id: "MH",
    code: "692",
    name: "Marshall Islands",
    name_cn: "马绍尔群岛",
    name_jp: "マーシャル諸島",
  },
  {
    id: "MQ",
    code: "596",
    name: "Martinique",
    name_cn: "马提尼克",
    name_jp: "マルティニーク",
  },
  {
    id: "YT",
    code: "262",
    name: "Mayotte",
    name_cn: "马约特",
    name_jp: "マヨット",
  },
  {
    id: "MU",
    code: "230",
    name: "Mauritius",
    name_cn: "毛里求斯",
    name_jp: "モーリシャス",
  },
  {
    id: "MR",
    code: "222",
    name: "Mauritania",
    name_cn: "毛里塔尼亚",
    name_jp: "モーリタニア",
  },
  {
    id: "AS",
    code: "1",
    name: "American Samoa",
    name_cn: "美属萨摩亚",
    name_jp: "アメリカ領サモア",
  },
  {
    id: "VI",
    code: "1",
    name: "United States Virgin Islands",
    name_cn: "美属维尔京群岛",
    name_jp: "アメリカ領ヴァージン諸島",
  },
  {
    id: "MN",
    code: "976",
    name: "Mongolia",
    name_cn: "蒙古",
    name_jp: "モンゴル",
  },
  {
    id: "MS",
    code: "1",
    name: "Montserrat",
    name_cn: "蒙特塞拉特",
    name_jp: "モントセラト",
  },
  {
    id: "BD",
    code: "880",
    name: "Bangladesh",
    name_cn: "孟加拉国",
    name_jp: "バングラデシュ",
  },
  { id: "PE", code: "51", name: "Peru", name_cn: "秘鲁", name_jp: "ペルー" },
  {
    id: "FM",
    code: "691",
    name: "Micronesia",
    name_cn: "密克罗尼西亚",
    name_jp: "ミクロネシア連邦",
  },
  {
    id: "MM",
    code: "95",
    name: "Myanmar",
    name_cn: "缅甸",
    name_jp: "ミャンマー",
  },
  {
    id: "MD",
    code: "373",
    name: "Moldova",
    name_cn: "摩尔多瓦",
    name_jp: "モルドバ共和国",
  },
  {
    id: "MA",
    code: "212",
    name: "Morocco",
    name_cn: "摩洛哥",
    name_jp: "モロッコ",
  },
  {
    id: "MC",
    code: "377",
    name: "Monaco",
    name_cn: "摩纳哥",
    name_jp: "モナコ",
  },
  {
    id: "MZ",
    code: "258",
    name: "Mozambique",
    name_cn: "莫桑比克",
    name_jp: "モザンビーク",
  },
  {
    id: "NA",
    code: "264",
    name: "Namibia",
    name_cn: "纳米比亚",
    name_jp: "ナミビア",
  },
  {
    id: "ZA",
    code: "27",
    name: "South Africa",
    name_cn: "南非",
    name_jp: "南アフリカ",
  },
  {
    id: "GS",
    code: "500",
    name: "South Georgia",
    name_cn: "南乔治亚和南桑威奇群岛",
    name_jp: "サウスジョージア・サウスサンドウィッチ諸島",
  },
  {
    id: "SS",
    code: "211",
    name: "South Sudan",
    name_cn: "南苏丹",
    name_jp: "南スーダン",
  },
  { id: "NR", code: "674", name: "Nauru", name_cn: "瑙鲁", name_jp: "ナウル" },
  {
    id: "NI",
    code: "505",
    name: "Nicaragua",
    name_cn: "尼加拉瓜",
    name_jp: "ニカラグア",
  },
  {
    id: "NP",
    code: "977",
    name: "Nepal",
    name_cn: "尼泊尔",
    name_jp: "ネパール",
  },
  {
    id: "NE",
    code: "227",
    name: "Niger",
    name_cn: "尼日尔",
    name_jp: "ニジェール",
  },
  { id: "NU", code: "683", name: "Niue", name_cn: "纽埃", name_jp: "ニウエ" },
  {
    id: "NO",
    code: "47",
    name: "Norway",
    name_cn: "挪威",
    name_jp: "ノルウェー",
  },
  {
    id: "NF",
    code: "672",
    name: "Norfolk Island",
    name_cn: "诺福克岛",
    name_jp: "ノーフォーク島",
  },
  { id: "PW", code: "680", name: "Palau", name_cn: "帕劳", name_jp: "パラオ" },
  {
    id: "PN",
    code: "64",
    name: "Pitcairn Islands",
    name_cn: "皮特凯恩群岛",
    name_jp: "ピトケアン",
  },
  {
    id: "PT",
    code: "351",
    name: "Portugal",
    name_cn: "葡萄牙",
    name_jp: "ポルトガル",
  },
  {
    id: "SV",
    code: "503",
    name: "El Salvador",
    name_cn: "萨尔瓦多",
    name_jp: "エルサルバドル",
  },
  {
    id: "WS",
    code: "685",
    name: "Samoa",
    name_cn: "萨摩亚",
    name_jp: "サモア",
  },
  {
    id: "RS",
    code: "381",
    name: "Serbia",
    name_cn: "塞尔维亚",
    name_jp: "セルビア",
  },
  {
    id: "SL",
    code: "232",
    name: "Sierra Leone",
    name_cn: "塞拉利昂",
    name_jp: "シエラレオネ",
  },
  {
    id: "SN",
    code: "221",
    name: "Senegal",
    name_cn: "塞内加尔",
    name_jp: "セネガル",
  },
  {
    id: "CY",
    code: "357",
    name: "Cyprus",
    name_cn: "塞浦路斯",
    name_jp: "キプロス",
  },
  {
    id: "SC",
    code: "248",
    name: "Seychelles",
    name_cn: "塞舌尔",
    name_jp: "セーシェル",
  },
  {
    id: "BL",
    code: "590",
    name: "Saint Barthélemy",
    name_cn: "圣巴泰勒米",
    name_jp: "サン・バルテルミー",
  },
  {
    id: "CX",
    code: "61",
    name: "Christmas Island",
    name_cn: "圣诞岛",
    name_jp: "クリスマス島",
  },
  {
    id: "ST",
    code: "239",
    name: "São Tomé and Príncipe",
    name_cn: "圣多美和普林西比",
    name_jp: "サントメ・プリンシペ",
  },
  {
    id: "SH",
    code: "290",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    name_cn: "圣赫勒拿",
    name_jp: "セントヘレナ・アセンションおよびトリスタンダクーニャ",
  },
  {
    id: "KN",
    code: "1",
    name: "Saint Kitts and Nevis",
    name_cn: "圣基茨和尼维斯",
    name_jp: "セントクリストファー・ネイビス",
  },
  {
    id: "LC",
    code: "1",
    name: "Saint Lucia",
    name_cn: "圣卢西亚",
    name_jp: "セントルシア",
  },
  {
    id: "SM",
    code: "378",
    name: "San Marino",
    name_cn: "圣马力诺",
    name_jp: "サンマリノ",
  },
  {
    id: "PM",
    code: "508",
    name: "Saint Pierre and Miquelon",
    name_cn: "圣皮埃尔和密克隆群岛",
    name_jp: "サンピエール島・ミクロン島",
  },
  {
    id: "VC",
    code: "1",
    name: "Saint Vincent and the Grenadines",
    name_cn: "圣文森特和格林纳丁斯",
    name_jp: "セントビンセントおよびグレナディーン諸島",
  },
  {
    id: "LK",
    code: "94",
    name: "Sri Lanka",
    name_cn: "斯里兰卡",
    name_jp: "スリランカ",
  },
  {
    id: "SK",
    code: "421",
    name: "Slovakia",
    name_cn: "斯洛伐克",
    name_jp: "スロバキア",
  },
  {
    id: "SI",
    code: "386",
    name: "Slovenia",
    name_cn: "斯洛文尼亚",
    name_jp: "スロベニア",
  },
  {
    id: "SJ",
    code: "47",
    name: "Svalbard and Jan Mayen",
    name_cn: "斯瓦尔巴和扬马延",
    name_jp: "スヴァールバル諸島およびヤンマイエン島",
  },
  {
    id: "SZ",
    code: "268",
    name: "Eswatini",
    name_cn: "斯威士兰",
    name_jp: "スワジランド",
  },
  {
    id: "SD",
    code: "249",
    name: "Sudan",
    name_cn: "苏丹",
    name_jp: "スーダン",
  },
  {
    id: "SR",
    code: "597",
    name: "Suriname",
    name_cn: "苏里南",
    name_jp: "スリナム",
  },
  {
    id: "SB",
    code: "677",
    name: "Solomon Islands",
    name_cn: "所罗门群岛",
    name_jp: "ソロモン諸島",
  },
  {
    id: "SO",
    code: "252",
    name: "Somalia",
    name_cn: "索马里",
    name_jp: "ソマリア",
  },
  {
    id: "TJ",
    code: "992",
    name: "Tajikistan",
    name_cn: "塔吉克斯坦",
    name_jp: "タジキスタン",
  },
  { id: "TH", code: "66", name: "Thailand", name_cn: "泰国", name_jp: "タイ" },
  {
    id: "TZ",
    code: "255",
    name: "Tanzania",
    name_cn: "坦桑尼亚",
    name_jp: "タンザニア",
  },
  { id: "TO", code: "676", name: "Tonga", name_cn: "汤加", name_jp: "トンガ" },
  {
    id: "TC",
    code: "1",
    name: "Turks and Caicos Islands",
    name_cn: "特克斯和凯科斯群岛",
    name_jp: "タークス・カイコス諸島",
  },
  {
    id: "TT",
    code: "1",
    name: "Trinidad and Tobago",
    name_cn: "特立尼达和多巴哥",
    name_jp: "トリニダード・トバゴ",
  },
  {
    id: "TN",
    code: "216",
    name: "Tunisia",
    name_cn: "突尼斯",
    name_jp: "チュニジア",
  },
  {
    id: "TV",
    code: "688",
    name: "Tuvalu",
    name_cn: "图瓦卢",
    name_jp: "ツバル",
  },
  {
    id: "TM",
    code: "993",
    name: "Turkmenistan",
    name_cn: "土库曼斯坦",
    name_jp: "トルクメニスタン",
  },
  {
    id: "TK",
    code: "690",
    name: "Tokelau",
    name_cn: "托克劳",
    name_jp: "トケラウ",
  },
  {
    id: "WF",
    code: "681",
    name: "Wallis and Futuna",
    name_cn: "瓦利斯和富图纳",
    name_jp: "ウォリス・フツナ",
  },
  {
    id: "VU",
    code: "678",
    name: "Vanuatu",
    name_cn: "瓦努阿图",
    name_jp: "バヌアツ",
  },
  {
    id: "GT",
    code: "502",
    name: "Guatemala",
    name_cn: "危地马拉",
    name_jp: "グアテマラ",
  },
  {
    id: "VE",
    code: "58",
    name: "Venezuela",
    name_cn: "委内瑞拉",
    name_jp: "ベネズエラ・ボリバル共和国",
  },
  {
    id: "BN",
    code: "673",
    name: "Brunei",
    name_cn: "文莱",
    name_jp: "ブルネイ・ダルサラーム",
  },
  {
    id: "UG",
    code: "256",
    name: "Uganda",
    name_cn: "乌干达",
    name_jp: "ウガンダ",
  },
  {
    id: "UA",
    code: "380",
    name: "Ukraine",
    name_cn: "乌克兰",
    name_jp: "ウクライナ",
  },
  {
    id: "UY",
    code: "598",
    name: "Uruguay",
    name_cn: "乌拉圭",
    name_jp: "ウルグアイ",
  },
  {
    id: "UZ",
    code: "998",
    name: "Uzbekistan",
    name_cn: "乌兹别克斯坦",
    name_jp: "ウズベキスタン",
  },
  {
    id: "GR",
    code: "30",
    name: "Greece",
    name_cn: "希腊",
    name_jp: "ギリシャ",
  },
  {
    id: "EH",
    code: "212",
    name: "Western Sahara",
    name_cn: "西撒哈拉",
    name_jp: "西サハラ",
  },
  {
    id: "NC",
    code: "687",
    name: "New Caledonia",
    name_cn: "新喀里多尼亚",
    name_jp: "ニューカレドニア",
  },
  {
    id: "NZ",
    code: "64",
    name: "New Zealand",
    name_cn: "新西兰",
    name_jp: "ニュージーランド",
  },
  {
    id: "HU",
    code: "36",
    name: "Hungary",
    name_cn: "匈牙利",
    name_jp: "ハンガリー",
  },
  {
    id: "JM",
    code: "1",
    name: "Jamaica",
    name_cn: "牙买加",
    name_jp: "ジャマイカ",
  },
  {
    id: "AM",
    code: "374",
    name: "Armenia",
    name_cn: "亚美尼亚",
    name_jp: "アルメニア",
  },
  {
    id: "YE",
    code: "967",
    name: "Yemen",
    name_cn: "也门",
    name_jp: "イエメン",
  },
  { id: "IQ", code: "964", name: "Iraq", name_cn: "伊拉克", name_jp: "イラク" },
  {
    id: "IL",
    code: "972",
    name: "Israel",
    name_cn: "以色列",
    name_jp: "イスラエル",
  },
  {
    id: "VG",
    code: "1",
    name: "British Virgin Islands",
    name_cn: "英属维尔京群岛",
    name_jp: "イギリス領ヴァージン諸島",
  },
  {
    id: "IO",
    code: "246",
    name: "British Indian Ocean Territory",
    name_cn: "英属印度洋领地",
    name_jp: "イギリス領インド洋地域",
  },
  {
    id: "JO",
    code: "962",
    name: "Jordan",
    name_cn: "约旦",
    name_jp: "ヨルダン",
  },
  {
    id: "VN",
    code: "84",
    name: "Vietnam",
    name_cn: "越南",
    name_jp: "ベトナム",
  },
  {
    id: "ZM",
    code: "260",
    name: "Zambia",
    name_cn: "赞比亚",
    name_jp: "ザンビア",
  },
  {
    id: "JE",
    code: "44",
    name: "Jersey",
    name_cn: "泽西岛",
    name_jp: "ジャージー",
  },
  { id: "TD", code: "235", name: "Chad", name_cn: "乍得", name_jp: "チャド" },
  {
    id: "GI",
    code: "350",
    name: "Gibraltar",
    name_cn: "直布罗陀",
    name_jp: "ジブラルタル",
  },
  { id: "CL", code: "56", name: "Chile", name_cn: "智利", name_jp: "チリ" },
  {
    id: "CF",
    code: "236",
    name: "Central African Republic",
    name_cn: "中非共和国",
    name_jp: "中央アフリカ共和国",
  },
];
