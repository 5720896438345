import React, { Component, type ReactNode } from "react";
import { ErrorComp } from "./ErrorComp";

interface ErrorBoundaryProps {
  children: ReactNode;
  error?: Error;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Error caught by ErrorBoundary: ", error, errorInfo);
  }

  render() {
    const { children, error } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorComp error={error} />;
    }

    return children;
  }
}

export default ErrorBoundary;
