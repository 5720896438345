import * as const_enum from "@api/http_pms/const/const_enum";

export type ConstOptionMap<
  K extends PropertyKey,
  T extends Record<string, any> = {},
> = Record<K, ConstOption<K, T>>;

export type ConstOption<
  K extends PropertyKey,
  T extends Record<string, any> = {},
> = {
  label: string;
  value: K;
  hidden?: boolean;
  rank?: number;
} & T;

export interface Option {
  value: string;
  label: string;
}

export const AREA_OPTION_MAP: ConstOptionMap<const_enum.Area> = {
  [const_enum.Area.UnknownArea]: {
    value: const_enum.Area.UnknownArea,
    label: "",
    hidden: true,
  },
  [const_enum.Area.US]: {
    value: const_enum.Area.US,
    label: "美国",
  },
  [const_enum.Area.JP]: {
    value: const_enum.Area.JP,
    label: "日本",
  },
  [const_enum.Area.CN]: {
    value: const_enum.Area.CN,
    label: "中国",
  },
};

export const CURRENCY_OPTION_MAP: ConstOptionMap<
  const_enum.Currency,
  { letterLabel: string; symbol: string; hasDecimal: boolean }
> = {
  [const_enum.Currency.UnknownCurrency]: {
    value: const_enum.Currency.UnknownCurrency,
    label: "",
    letterLabel: "",
    symbol: "¥",
    hidden: true,
    hasDecimal: false,
  },
  [const_enum.Currency.JPY]: {
    value: const_enum.Currency.JPY,
    label: "JPY - 日元",
    letterLabel: "JPY",
    symbol: "¥",
    hasDecimal: false,
  },
  [const_enum.Currency.CNY]: {
    value: const_enum.Currency.CNY,
    label: "CNY - 元",
    letterLabel: "CNY",
    symbol: "¥",
    hasDecimal: true,
  },
  [const_enum.Currency.USD]: {
    value: const_enum.Currency.USD,
    label: "USD - 美元",
    letterLabel: "USD",
    symbol: "$",
    hasDecimal: true,
  },
};
