import { type OrderInDetail } from "@api/http_resv/order/order_type";
import clsx from "clsx";
import { Card } from "@/components";
import { useIsPC } from "@/stores";

interface CheckInMethodCardProps {
  data: OrderInDetail;
}

export const CheckInMethodCard: React.FC<CheckInMethodCardProps> = ({
  data,
}) => {
  const isPC = useIsPC();

  if (data?.hideCheckInGuideCard) {
    return null;
  }

  return (
    <Card
      title="入住方式"
      className={clsx({
        "p-6": isPC,
      })}
    >
      {data?.hideCheckInGuideText ? (
        <div className="mt-3 rounded-lg bg-[#F5F7FB] py-2 text-center">
          <span className="text-text-666 whitespace-pre-line  text-sm">
            入住当日至退房前日可查看
          </span>
        </div>
      ) : (
        <div className="text-text-666 whitespace-pre-line pt-3 text-sm">
          {data?.checkInGuide}
        </div>
      )}
    </Card>
  );
};
