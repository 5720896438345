import React from "react";
import type * as room_type from "@api/http_resv/room/room_type";
import type * as common_type from "@api/http_pms/common/common_type";
import { Image } from "react-vant";
import clsx from "clsx";
import LocationIcon from "@/assets/icons/location.svg?react";
import { Card } from "@/components/Card";
import { PLACEHOLDER_TEXT } from "@/constants/text";
import { getMoneyText } from "@/utils/money";
import { getRoomInfo } from "@/utils/roomInfo";
import { PATHS } from "@/constants/path";
import { useCustomNavigate } from "@/hooks";
import { useIsPC } from "@/stores";

interface RoomCardProps {
  extra?: React.ReactNode;
  header?: React.ReactNode;
  disableLink?: boolean;
  imageUri: string;
  room: room_type.Room;
  quotedPrice?: common_type.CurrencyAmount;
  orderId?: string;
  onClick?: () => void;
}

export const RoomCardHeaderWrapper = ({
  children,
}: React.PropsWithChildren) => {
  return (
    <div className="flex justify-between border-b border-dashed border-b-[#e5e5e55] pb-2">
      {children}
    </div>
  );
};

export const RoomCard: React.FC<RoomCardProps> = ({
  extra,
  header,
  disableLink = false,
  room,
  quotedPrice,
  imageUri,
  orderId,
  ...props
}) => {
  const roomInfoText = getRoomInfo({
    area: room.roomSize,
    bed: room.singleBedCount,
    doubleBed: room.doubleBedCount,
  });
  const customNavigate = useCustomNavigate();
  const isPC = useIsPC();

  const onGoToRoomDetail = (e: React.MouseEvent) => {
    if (disableLink) return;
    customNavigate(PATHS.ROOM, {
      id: room.id,
      state: {
        backPath: PATHS.ORDER,
        orderId,
      },
    });

    // 在订单列表页点击列表项中的房间卡片，会触发房间详情页的跳转
    // 在订单列表页点击列表项，会触发订单详情页的跳转
    // 此时需要阻止事件冒泡，防止点击房间卡片的时候触发订单详情页的跳转
    e.stopPropagation();
  };

  return (
    <Card
      className={clsx(
        isPC ? "w-[80vw] max-w-screen-lg px-6" : "w-full",
        "mx-auto ",
      )}
      {...props}
    >
      {header}

      <div className="flex cursor-pointer py-3" onClick={onGoToRoomDetail}>
        <div
          className={clsx(
            isPC ? "size-[120px]" : "size-[84px]",
            "mr-3 overflow-hidden rounded-lg",
          )}
        >
          <Image src={imageUri} alt="房间图片" fit="cover" />
        </div>
        <div className="flex flex-1 flex-col">
          <div className="text-text-1 mb-2 line-clamp-1 text-ellipsis text-base font-medium">
            {room.title || PLACEHOLDER_TEXT}
          </div>
          <div
            className={clsx({
              "flex items-center gap-3": isPC,
            })}
          >
            {room.roomSize > 0 && (
              <div className="mb-2 flex">
                <span className="text-text-666 text-sm">{roomInfoText}</span>
              </div>
            )}
            <div className="mb-2 flex flex-1 flex-row items-center justify-between">
              {room.personCapacity > 0 ? (
                <span className="text-easbnb-brand text-sm">
                  可住{room.personCapacity}人
                </span>
              ) : (
                <i />
              )}
              <span className="text-text-2 text-sm">
                {quotedPrice && quotedPrice.amount !== 0 && (
                  <div>
                    {getMoneyText(quotedPrice.amount, quotedPrice.currency, {
                      hidePlusSign: true,
                    })}
                  </div>
                )}
              </span>
            </div>
          </div>
          {isPC && <CardLocation address={room.address} extra={extra} />}
        </div>
      </div>

      {!isPC && room.address && (
        <div className="flex items-center space-x-2 border-t border-dashed border-t-[#e5e5e55] pt-3.5">
          <LocationIcon className="size-4" />
          <div className="text-text-666 text-sm">{room.address}</div>
        </div>
      )}

      {!isPC && extra}
    </Card>
  );
};

const CardLocation: React.FC<{
  address?: string;
  extra?: React.ReactNode;
}> = ({ address, extra }) => {
  return (
    <div className={clsx("mt-8 flex items-center justify-between")}>
      {address ? (
        <div className="flex items-center space-x-2">
          <LocationIcon className="size-4" />
          <div className="text-text-666 text-sm">{address}</div>
        </div>
      ) : (
        <i />
      )}
      {extra}
    </div>
  );
};
