import React, { type FC } from "react";
import { cn } from "@/lib/utils";
import { useSafeAreaInsetBottom } from "@/hooks/useSafeAreaInsetBottom";

interface FixedBottomWrapperProps {
  children?: React.ReactNode;
  className?: string;
}

export const FixedBottomWrapper: FC<FixedBottomWrapperProps> = ({
  children,
  className,
  ...props
}) => {
  const safeAreaInsetBottom = useSafeAreaInsetBottom();

  return (
    <div
      className={cn(
        "fixed inset-x-0 bottom-0 mx-auto max-w-[750px]",
        safeAreaInsetBottom > 0 ? "pb-safe-area-bottom" : "pb-6",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};
